<template>
  <div class="sideBar">
    <div class="sideBar__logo">
      <router-link to="/"
      ><img src="../../assets/img/logo_icon.svg" alt=""
      /></router-link>
    </div>
    <div class="sideBar__components">
      <ul class="sideBar__list">
        <NavigationGroup
            v-for="(group, i) in links"
            v-bind:key="i"
            :group="group"
        />
      </ul>
    </div>
  </div>
  <div class="header">
    <nav class="navbar">
      <div class="header-title">
        <a
            class="navbar-brand"
            href="javascript:void(0)"
            @click.prevent="onTitleClick"
        >{{ title }}</a
        >
      </div>
    </nav>
  </div>
  <div class="context_menu">
    <acceptance-contract-modal></acceptance-contract-modal>
    <router-view></router-view>
  </div>
</template>

<script>

import NavigationGroup from "@/components/sidebar/NavigationGroup";

export default {
  name: "Registration",
  components: {
    NavigationGroup
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
    links() {
      return this.$store.getters["navLinks/getUserNavLinks"];
    },
  }
}
</script>

<style scoped>

</style>
