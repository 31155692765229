import { useRouter } from 'vue-router'

const store = {
  namespaced: true,
  state() {
    return {
      links: {
        users: { title: "Пользователи", icon: "user", link: "/users" },
        registration: {
          title: "Регистрация",
          link: "/registration/new-user"
        },
        legalEntity: {
          title: 'Заполнить реквизиты',
          link: '/registration/legal-entity-edit',
        },
        points: { title: "Справочник ПВЗ", icon: "adresses", link: "/points" },
        partners: { title: "Контрагенты", icon: "users", link: "/partners" },
        freights: { title: "Перевозки", icon: "transport", link: "/freights" },
        shipments: { title: "Отправления", icon: "send", link: "/shipments" },
        tariffs: { title: "Тарифы", icon: "money", link: "/tariffs" },
        clients: { title: "Данные физлиц", icon: "back", link: "/clients" },
        news: { title: "Новости", icon: "news", link: "https://t.me/+Vs8jV7MQXOBlNzli", target: "_blank" },
        helpdesk: { title: "Helpdesk", link: "/helpdesk", icon: "help" },
        statistic: {
          title: 'Статистика',
          icon: 'statistic',
          link: '/statistic'
        },
        instructions: {
          title: "Инструкции",
          icon: "guide",
          link: "/instructions",
        },
        settings: {
          title: "Настройки системы",
          icon: "wrench",
          link: "/settings",
        },

        address: {
          title: "ПВЗ",
          icon: "adresses",
          link: "/address",
        },
        ppActs: { title: "Акты приёма-передачи", icon: "send", link: "/pp-acts" },
        multiAccept: { title: "Мультиприем", icon: "send", link: "/multi-accept" },
        accept: { title: "Прием отправлений", icon: "send", link: "/accept" },
        give: { title: "Выдача отправлений", icon: "give", link: "/give" },
        back: { title: "Прием возвратов", icon: "back", link: "/back" },
        warehouse: {
          title: "Отправка на склад",
          icon: "warehouse",
          link: "/warehouse",
        },
        searchShipments: {
          title: "Поиск отправлений",
          icon: "profile",
          link: "/shipments",
        },
        report: { title: "Отчеты", icon: "report", link: "/report" },
        inventory: {
          title: "Инвентаризация",
          icon: "inventory",
          link: "/inventory",
        },
        folder: {
          title: "Адресное хранение",
          icon: "cell",
          link: "/folder",
        },
        help: { title: "Помощь", id: 9, icon: "chat", link: "/help" },
      },
    };
  },
  getters: {
    getUserNavLinks(state, getters, rootState, rootGetters) {
      console.log(0)
      const userRoleId = rootGetters["user/role_id"];
      console.log(1)
      const points = rootGetters['user/points'];
      console.log(2)
      const router = useRouter();
      console.log('userRoleId')
      console.log(userRoleId)
      let currentPathObject = router.currentRoute.value;
      let links = [];

      if (userRoleId === -1 && currentPathObject.name === 'registration') {
        let {registration} = state.links;

        return [registration];
      }

      if (userRoleId < 0) return links;

      let {
        users,
        freights,
        shipments,
        tariffs,
        clients,
        news,
        instructions,
        address,
        ppActs,
        multiAccept,
        accept,
        give,
        warehouse,
        searchShipments,
        report,
        statistic,
        helpdesk,
        registration,
        legalEntity
      } = state.links;

      switch (userRoleId) {
        case -1:
          links = [
            registration,
          ]
          break;
        case 0:
        case 1:
          // admin
          report.links = [
            {
              title: "Отчет по ИМ",
              link: "/report",
            },
          ];

          users.links = [
            {
              title: 'Заявки на регистрацию',
              link: '/users/requestsRegistration'
            }
          ];

          shipments.links = [
            {
              title: 'Установка статусов',
              link: '/shipments/set-status-for-shipments'
            }
          ];

          links = [
            users,
            freights,
            shipments,
            report,
            tariffs,
            clients,
           statistic,
            news,
            ppActs,
            instructions,
            helpdesk,
          ];
          break;
          case 6:
          case 14:
          // operator
          report.links = [
            {
              title: "Отчет остатки",
              link: "/report/remains",
            },
            {
              title: "Отчет утилизация",
              link: "/report/destroyed",
            },
          ];

          address.links = points.map(item => {
            return {
              title: `${item.street}, ${item.house}`,
              id: item.id,
              link: `/address/${item.id}`
            };
          });

          if (points.length) {
            links = [
              address,
              report,
              warehouse,
              freights,
              ppActs,
              multiAccept,
              accept,
              give,
              searchShipments,
           //   statistic,
              news,
              helpdesk,
            ];
          } else {
            links = [
              address,
              report,
              statistic,
              news,
              helpdesk,
            ];
          }

          break;
        case 9:
          // multy op
          report.links = [
            {
              title: "Отчет франчайзи", // готов
              link: "/report",
            },
            {
              title: "Отчет остатки",
              link: "/report/remains",
            },
          ];
          address.links = points.map(item => {
            return {
              title: `${item.street}, ${item.house}`,
              id: item.id,
              link: `/address/${item.id}`
            };
          });

          if (points.length) {
            links = [
              address,
              report,
              warehouse,
              freights,
              searchShipments,
         //     statistic,
              news,
              ppActs,
              helpdesk,
            ];
          } else {
            links = [
              address,
              report,
          //    statistic,
              news,
             helpdesk,
            ];
          }
          break;
        case 12:
          links = [
            legalEntity
          ]
          break;
        default:
          console.log("No links for this role");
      }
      return links;
    },
  },
};


export default store;
