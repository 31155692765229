<template>
  <div class="comment" v-if="message && !isAdmin">
    <el-alert :title="message" type="info" effect="dark" :closable="false"/>
  </div>

  <el-form ref="ruleFormRef" :model="registerForm" :rules="rules" :label-position="labelPosition"
           label-width="120px">
    <div style="padding-bottom: 10px;">
      <el-form-item label="Инн" prop="inn">
        <el-input v-model="registerForm.inn" :disabled="editDisabled"/>
      </el-form-item>

      <el-button :disabled="editDisabled || (registerForm.inn.length === 0)" size="small" v-loading="loadingRequisites"  type="primary" @click="loadRequisites()">
        Подгрузить реквизиты
      </el-button>
    </div>

    <el-form-item  label="Организационно-правовая форма и фирменное наименование (полное)" prop="full_name">
      <el-input v-model="registerForm.full_name" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item  label="Организационно-правовая форма и фирменное наименование (краткое)" prop="short_name">
      <el-input v-model="registerForm.short_name" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item disabled="editDisabled"
        label="Свидетельство о постановке на учет юридического лица в налоговом органе по месту нахождения на территории РФ (дата и номер, кем выдано)"
        prop="registration_certificate">
      <el-input v-model="registerForm.registration_certificate" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="ОГРН" prop="ogrn">
      <el-input v-model="registerForm.ogrn" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="ОКПО" prop="okpo">
      <el-input v-model="registerForm.okpo" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="ОКАТО" prop="okato">
      <el-input v-model="registerForm.okato" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="ОКОГУ" prop="okogy">
      <el-input v-model="registerForm.okogy" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="КПП" prop="kpp">
      <el-input v-model="registerForm.kpp" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="ОКВЭД" prop="okwed">
      <el-input v-model="registerForm.okwed" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Юридический адрес" prop="jur_address">
      <el-input v-model="registerForm.jur_address" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Почтовый адрес (Фактический)" prop="postal_address">
      <el-input v-model="registerForm.postal_address" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Телефон" prop="phone">
      <el-input v-model="registerForm.phone" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Адрес электронной почты" prop="email_address">
      <el-input v-model="registerForm.email_address" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="ФИО руководителя" prop="dir_fio">
      <el-input v-model="registerForm.dir_fio" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Расчетный счет" prop="bank_account">
      <el-input v-model="registerForm.bank_account" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Корреспондентский счет" prop="bank_corr">
      <el-input v-model="registerForm.bank_corr" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="БИК банка" prop="bank_bik">
      <el-input v-model="registerForm.bank_bik" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Наименование банка" prop="bank_name">
      <el-input v-model="registerForm.bank_name" :disabled="editDisabled"/>
    </el-form-item>

    <el-form-item label="Адрес точки выдачи" prop="point_address">
      <el-autocomplete
          style="width: 100%;"
          v-model="registerForm.point_address"
          :disabled="editDisabled"
          :fetch-suggestions="getDaDataSuggestions"
          placeholder="Адрес точки выдачи"
          :trigger-on-focus="false"
          :debounce="1000"
      />
    </el-form-item>

    <el-form-item label="Тип здания, в котором расположена точка выдачи" prop="type_building">
      <el-select v-model="registerForm.type_building" :model-value=BUILDING_TYPES[registerForm.type_building]  placeholder="Тип здания" :disabled="editDisabled">
        <el-option :label="buildingType.name" :value="buildingType.id" v-for="buildingType in buildingTypes" :key="buildingType.id"></el-option>
      </el-select>
    </el-form-item>

    <div style="padding: 20px 0" v-if="isViewPhotoUpload">
      <el-form-item label="Фото точки выдачи" prop="photo_point">
        <el-upload :disabled="editDisabled"
            v-model:file-list="fileList"
            :drag="true"
            accept="image/jpeg,image/gif,image/png"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="deletePhotoPoint"
            :auto-upload="true"
            :http-request="uploadFile"
            :limit="photoLimit"
            :on-exceed="notificationMaxLimit"
            :on-change="handleChange"
        >
          <el-icon v-if="!editDisabled">
            <component :is="Plus"/>
          </el-icon>
        </el-upload>

        <el-dialog v-model="dialogVisible">
          <img class="w-100" :src="this.dialogImageUrl" alt="Preview Image"/>
        </el-dialog>
      </el-form-item>
    </div>

    <el-form-item label="Описание “Как пройти”" prop="path_description">
      <el-input v-model="registerForm.path_description" :disabled="editDisabled" />
    </el-form-item>

    <div style="display:flex;">
      <el-form-item>
        <el-button v-loading="loadingSave" :disabled="editDisabled"  type="primary" @click="saveForm(ruleFormRef)">
          Сохранить
        </el-button>
      </el-form-item>

      <el-form-item v-if="!isAdmin" style="padding-left: 10px;">
        <el-button v-loading="loadingSendCheck" :disabled="registerForm.status !== REQUEST_STATUS_NEW" type="primary" @click="sendCheckForm(ruleFormRef)">
          {{ registerForm.status !== REQUEST_STATUS_NEW ? 'Заявка отправлена' : 'Отправить на проверку' }}
        </el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import {reactive, ref} from "vue";
import {Plus} from "@element-plus/icons-vue";
import {REQUEST_STATUS_CHECK, BUILDING_TYPES, REQUEST_STATUS_NEW} from "@/constants";
import {ElMessageBox, ElNotification } from "element-plus";
import { UploadUserFile } from 'element-plus'

const baseURL = process.env.VUE_APP_API_ROOT;
const getPhotoUrl = baseURL + '/v2/registration-user/getPhotoPoint/';

export default {
  name: "RegistrationLegalEntityForm",
  props: {
    isAdmin: Boolean,
    userId: Number,
    requestId: Number
  },
  setup() {
    const ruleFormRef = ref()
    const labelPosition = ref('top')
    const fileList = ref<UploadUserFile>([])

    const registerForm = reactive({
      request_id: '',
      inn: '',
      full_name: '',
      short_name: '',
      registration_certificate: '',
      okpo: '',
      okato: '',
      okogy: '',
      kpp: '',
      okwed: '',
      jur_address: '',
      postal_address: '',
      phone: '',
      email_address: '',
      dir_fio: '',
      bank_account: '',
      bank_corr: '',
      bank_bik: '',
      bank_name: '',
      point_address: '',
      type_building: '',
      path_description: '',
      status: REQUEST_STATUS_NEW,
    })

    const rules = reactive({
      inn: [
        {
          required: true,
          message: 'Введите инн',
          trigger: 'blur'
        },
      ],
      full_name: [
        {
          required: true,
          message: 'Введите полное фирменное наименование',
          trigger: 'blur'
        },
      ],
      short_name: [
        {
          required: true,
          message: 'Введите сокращенное фирменное наименование',
          trigger: 'blur'
        },
      ],
      registration_certificate: [
        {
          required: true,
          message: 'Введите свидетельство о постановке на учет юридического лица в налоговом органе',
          trigger: 'blur'
        },
      ],
      phone: [
        {
          required: true,
          message: 'Введите телефон',
          trigger: 'blur'
        },
      ],
      jur_address: [
        {
          required: true,
          message: 'Введите юридический адрес',
          trigger: 'blur'
        },
      ],
      postal_address: [
        {
          required: true,
          message: 'Введите почтовый адрес',
          trigger: 'blur'
        },
      ],
      email_address: [
        {
          required: true,
          message: 'Введите email',
          trigger: 'blur'
        },
      ],
      dir_fio: [
        {
          required: true,
          message: 'Введите ФИО руководителя',
          trigger: 'blur'
        },
      ],
      bank_account: [
        {
          required: true,
          message: 'Введите расчетный счет',
          trigger: 'blur'
        },
      ],
      bank_corr: [
        {
          required: true,
          message: 'Введите корреспондентский счет',
          trigger: 'blur'
        },
      ],
      bank_bik: [
        {
          required: true,
          message: 'Введите БИК банка',
          trigger: 'blur'
        },
      ],
      bank_name: [
        {
          required: true,
          message: 'Введите наименование банка',
          trigger: 'blur'
        },
      ],
      point_address: [
        {
          required: true,
          message: 'Введите адрес точки выдачи',
          trigger: 'blur'
        },
      ],
    })

    return {
      ruleFormRef,
      rules,
      registerForm,
      labelPosition,
      fileList,
    }
  },
  created() {
    if (this.isAdmin === false) {
      this.getRequestRegistration();
    }
  },
  data() {
    return {
      Plus,
      REQUEST_STATUS_CHECK: REQUEST_STATUS_CHECK,
      REQUEST_STATUS_NEW: REQUEST_STATUS_NEW,
      BUILDING_TYPES: BUILDING_TYPES,
      dialogImageUrl: '',
      dialogVisible: false,
      newPhotoId: null,
      isViewPhotoUpload: false,
      loadingSave: false,
      loadingSendCheck: false,
      loadingRequisites: false,
      photoLimit: 3,
      message: '',
      buildingTypes: [
        {
          id: 0,
          name: "ТЦ"
        },
        {
          id: 1,
          name: "БЦ"
        },
        {
          id: 2,
          name: "Супермаркет"
        },
        {
          id: 3,
          name: "Жилой дом"
        },
        {
          id: 4,
          name: "Другое"
        },
      ]
    }
  },
  computed: {
    editDisabled() {
      return this.registerForm.request_id !== '' && this.registerForm.status !== REQUEST_STATUS_NEW && !this.isAdmin
    }
  },
  methods: {
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true
    },

    saveForm() {
      this.loadingSave = true;
      if (this.registerForm.inn.length === 0) {
        ElNotification({
          title: 'Ошибка',
          message: 'Заполните поле ИНН',
          type: 'error',
        });
        this.loadingSave = false;
        return;
      }

      this.$store.dispatch('apiV2RegistrationStore/saveInfoUserLegalEntity', this.registerForm).then((result) => {
        this.registerForm.request_id = result.legalEntity.id;
        this.registerForm.status = result.legalEntity.status;
        this.fileList = [];

        this.isViewPhotoUpload = true;

        this.loadingSave = false;
        ElNotification({
          title: 'Успешно',
          message: 'Заявка сохранена',
          type: 'success',
        });
      }, error => {
        ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });

        this.loadingSave = false;
      });
    },

    sendCheckForm(formEl) {
      if (!formEl) return
      formEl.validate(async (valid) => {
        if (valid) {
          if (this.fileList.length === 0) {
            ElNotification({
              title: 'Ошибка',
              message: 'Загрузите фото точки выдачи',
              type: 'error',
            });

            return;
          }

          await ElMessageBox.confirm(
              'Вы уверены что хотитите отправить заявку?',
              {
                confirmButtonText: 'Да',
                cancelButtonText: 'Нет',
                cancelButtonClass: 'el-button--info'
              },
          )

          this.loadingSendCheck = true;

          this.$store.dispatch('apiV2RegistrationStore/sendRequestRegistrationToCheck', this.registerForm).then(() => {
            this.loadingSendCheck = false;
            this.registerForm.status = REQUEST_STATUS_CHECK;

            ElNotification({
              title: 'Успешно',
              message: 'Заявка успешно отправлена на проверку',
              type: 'success',
            });
          }, error => {
            ElNotification({
              title: 'Ошибка',
              message: error.message,
              type: 'error',
            });
            this.loadingSendCheck = false;

          })
        } else {
          ElNotification({
            title: 'Ошибка',
            message: 'Не заполнены все обязательные поля',
            type: 'error',
          });

          this.loadingSendCheck = false;
        }
      })
    },

    async uploadFile(file) {
      let data = new FormData();

      data.append('attach', file.file);
      data.append('request_id', this.registerForm.request_id);

      await this.$store.dispatch('apiV2RegistrationStore/uploadPhotoPoint', data).then((result) => {
        this.newPhotoId = result.photo.id;

        this.fileList.push({
          'id': result.photo.id,
          'url': getPhotoUrl + result.photo.id,
          'name': result.photo.name,
        });

        ElNotification({
          title: 'Успешно',
          message: 'Фото загружено',
          type: 'success',
        });
      });
    },

    deletePhotoPoint(deletePhoto) {
      let photoId = deletePhoto.id ?? deletePhoto.response;

      let index = this.fileList.map(photo => {
        return photo.id;
      }).indexOf(deletePhoto.id);

      this.fileList.splice(index, 1);

      this.$store.dispatch('apiV2RegistrationStore/deletePhotoPoint', {photoId: photoId}).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Фото удалено',
          type: 'success',
        });
      });
    },

    loadRequisites() {
      this.loadingRequisites = true;

      this.$store.dispatch('apiV2RegistrationStore/loadRequisitesLegalEntity', this.registerForm.inn).then((result) => {
        if (result.requisites.length === 0) {
          ElNotification({
            title: 'Не найдено',
            message: 'По инн ' + this.registerForm.inn + ' ничего не удалось найти',
            type: 'warning',
          });

          this.loadingRequisites = false;

          return;
        }

        this.registerForm.full_name = result.requisites[0].data.name.full_with_opf;
        this.registerForm.short_name = result.requisites[0].data.name.short_with_opf;

        this.registerForm.okpo = result.requisites[0].data.okpo;
        this.registerForm.ogrn = result.requisites[0].data.ogrn;
        this.registerForm.okato = result.requisites[0].data.okato;
        this.registerForm.okogy = result.requisites[0].data.okogu;
        this.registerForm.kpp = result.requisites[0].data.kpp;
        this.registerForm.okwed = result.requisites[0].data.okved;
        this.registerForm.jur_address = result.requisites[0].data.address.value;
        this.registerForm.dir_fio = result.requisites[0].data.management.name;

        this.loadingRequisites = false;

        ElNotification({
          title: 'Успешно',
          message: 'Реквизиты загружены',
          type: 'success',
        });
      });
    },

    loadForm(data) {
      if (data.infoUserLegalEntity !== null) {
        this.isViewPhotoUpload = true;

        this.registerForm.request_id = data.infoUserLegalEntity.id;
        this.registerForm.inn = data.infoUserLegalEntity.inn;
        this.registerForm.full_name = data.infoUserLegalEntity.full_name;
        this.registerForm.short_name = data.infoUserLegalEntity.short_name;
        this.registerForm.registration_certificate = data.infoUserLegalEntity.registration_certificate;
        this.registerForm.okpo = data.infoUserLegalEntity.okpo;
        this.registerForm.ogrn = data.infoUserLegalEntity.ogrn;
        this.registerForm.okato = data.infoUserLegalEntity.okato;
        this.registerForm.okogy = data.infoUserLegalEntity.okogy;
        this.registerForm.kpp = data.infoUserLegalEntity.kpp;
        this.registerForm.okwed = data.infoUserLegalEntity.okwed;
        this.registerForm.jur_address = data.infoUserLegalEntity.jur_address;
        this.registerForm.postal_address = data.infoUserLegalEntity.postal_address;
        this.registerForm.phone = data.infoUserLegalEntity.phone;
        this.registerForm.email_address = data.infoUserLegalEntity.email_address;
        this.registerForm.dir_fio = data.infoUserLegalEntity.dir_fio;
        this.registerForm.bank_account = data.infoUserLegalEntity.bank_account;
        this.registerForm.bank_corr = data.infoUserLegalEntity.bank_corr;
        this.registerForm.bank_bik = data.infoUserLegalEntity.bank_bik;
        this.registerForm.bank_name = data.infoUserLegalEntity.bank_name;
        this.registerForm.point_address = data.infoUserLegalEntity.point_address;
        this.registerForm.type_building = data.infoUserLegalEntity.type_building;
        this.registerForm.path_description = data.infoUserLegalEntity.path_description;
        this.registerForm.status = data.infoUserLegalEntity.status;

        this.message = data.infoUserLegalEntity.comment;

        this.fileList = data.infoUserLegalEntity.point_photos.map((photo) => ({
          id: photo.id,
          name: photo.name,
          url: getPhotoUrl + photo.id
        }));
      }
    },

    getDaDataSuggestions(address, returnResult) {
      this.$store.dispatch('apiV2RegistrationStore/getDaDataSuggestions', address).then((data) => {
        let result = [];

        data.addresses.forEach((item) => {
          result.push({value: item.value});
        });

        returnResult(result);
      })
    },

    clear() {
      this.$refs.ruleFormRef.resetFields()
    },

    handleChange(file) {
      file.response = this.newPhotoId;
    },

    loadFormDialog() {
      this.$store.dispatch('apiV2RegistrationStore/getRequestRegistrationById', this.requestId).then((data) => {
        this.loadForm(data);
      });
    },

    getRequestRegistration() {
      this.$store.dispatch('apiV2RegistrationStore/getInfoUserLegalEntity', this.userId).then((data) => {
        if (data !== null) {
          this.loadForm(data);
        }
      })
    },

    notificationMaxLimit() {
      ElNotification({
        title: 'Предупреждение',
        message: 'Загружено максимальное количество фотографий',
        type: 'warning',
      });
    }
  }
}
</script>

<style scoped>
  .comment {
    padding: 0 0 30px 0;
  }

  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 100%;
  }

  ::v-deep .loader__backdrop {
    display: none;
  }
</style>
