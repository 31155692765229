import client from './client'
import createCrud from './createCrud'


const {getAll, createItem, readItem, updateItem, deleteItem} = createCrud(client, '/shipments')

const getForFreight = async (filters) => {
  const query = Object.keys(filters).map(key => `${key}=${filters[key]}`)
  return await client.get(`/v2/shipments/getForFreight?${query.join('&')}`)
}
const surplus = async () => {
  return await client.get(`/shipments/surplus`)
};
const destroyed = async () => {
  return await client.get(`/shipments/destroyed`)
};
const issued = async (uuid, data) => {
  return await client.put(`/shipments/${uuid}/issued `, data)
};
const accept = async (id, data) => {
  return await client.put(`/shipments/${id}/accept`, data)
};
const placed = async (id, data) => {
  return await client.put(`/shipments/${id}/placed `, data)
};
const placedShipmentFromFreight = async (id, data) => {
  return await client.put(`/shipments/${id}/placedShipmentFromFreight `, data)
};
const code = async (uuid, shipmentCodeType) => {
  return await client.put(`/shipments/${uuid}/code`, { shipment_code_type: shipmentCodeType })
};

const getShipmentCode = async (id) => {
  return await client.get(`/shipments/get-id/${id}`);
};

const getPackageTypes = async () => {
  return await client.get('/package_types');
};

const getHistory = async (id) => {
  return await client.get(`/shipments/${id}/history`);
};

const cancelled = async (id, reasonId) => {
  return await client.put(`/shipments/${id}/cancelled`, { refusal_reason: reasonId } );
}

const multiChangeStatus = async (data) => {
  return await client.post(`/shipments/multi-change-status`, data)
};

export {
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
  surplus,
  destroyed,
  issued,
  accept,
  placed,
  placedShipmentFromFreight,
  code,
  cancelled,
  getPackageTypes,
  getHistory,
  getShipmentCode,
  multiChangeStatus,
  getForFreight,
}
