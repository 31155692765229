import Report from "./Report.vue";
import ReportRemains from "./ReportRemains.vue";
import ReportDestroyed from "@/views/report/ReportDestroyed";

export const reportRoutes = [
  {
    path: "/report",
    component: Report,
    name: "report",
    meta: { title: "Отчеты" },
  },
  {
    path: "/report/remains",
    component: ReportRemains,
    name: "report-remains",
    meta: { title: "Отчет остатки" },
  },
  {
    path: "/report/destroyed",
    component: ReportDestroyed,
    name: "report-destroyed",
    meta: { title: "Отчет утилизация" },
  },
];
