<template>
  <Datepicker
    v-model="dpValue"
    autoApply
    locale="ru"
    placeholder="Выберите дату"
    :format="format"
    :inputClassName="inputClassName"
    type="datepicker"
    hideInputIcon
    :enableTimePicker="false"
    @cleared="onClear"
    selectText="Выбрать"
    cancelText="Отмена"
  />
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

export default {
  name: "AppDatePicker",
  components: { Datepicker },
  props: {
    value: {
      type: [Date, String],
      // default: new Date(),
    },
    isInValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      format: "dd-MM-yyyy",
    };
  },
  computed: {
    dpValue: {
      get() {
        return this.value;
      },
      set(val) {
        // console.log(val);
        this.$emit("onUpdate", val);
      },
    },
    inputClassName() {
      let classes = [
        "form-control",
        "custom__dp",
        "modal-add-worker-form-style-input",
      ];
      if (this.isInValid) {
        classes.push("is-invalid");
      }
      return classes.join(" ");
    },
  },
  methods: {
    onClear() {
      this.$emit("onClear");
    },
  },
};
</script>
