<template>
  <div class="shipment-grid-container--admin-wrap">
    <div class="shipment-grid-container--admin">
      <div class="block-shadow">
        <h2 class="title-small-shipment">Отправитель</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ src_fio }}
        </p>
        <input
            v-else
            class="form-control style-profile-all-input"
            type="text"
            :value="src_fio"
            @input="onInput('src_fio', $event)"
            disabled
        />
      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Получатель</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ dst_fio }}
        </p>
        <input
            v-else
            class="form-control style-profile-all-input"
            type="text"
            :value="dst_fio"
            @input="onInput('dst_fio', $event)"
            disabled
        />
      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Статус</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ statusNameById }}
        </p>
        <div v-else>
          <Select
              :options="statusSelect"
              :value="parseInt(shipment.status) || -1"
              placeholder="Выберите статус"
              @select="onSelect('status', $event)"
              :isPlaceholderDisabled="true"
              :class="{
              'is-invalid':
                (v$.shipment.status.$dirty && v$.shipment.status.$invalid) ||
                backendErrors.status,
            }"
          />

          <div
              v-if="
              (v$.shipment.status.$dirty && v$.shipment.status.$invalid) ||
              backendErrors.status
            "
              class="invalid-feedback"
          >
            <p
                v-if="v$.shipment.status.$dirty && v$.shipment.status.$invalid"
                class="invalid-feedback-text"
            >Неверный формат</p
            >
            <p v-if="backendErrors.status" class="invalid-feedback-text">{{
                backendErrors.status[0]
              }}</p>
          </div>
        </div>

        <div v-if="this.isAdmin">
          <h2 class="title-small-shipment">Будущий статус</h2>
          <p class="shipment-grid-text">
            {{ toBeStatusNameById }}
          </p>
        </div>

      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Принято на</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ getPointDescription(shipment.point_src) }}
        </p>
        <Select
            v-else
            :options="pointSelect"
            :value="shipment.src_point_id"
            placeholder="Выберите ПВЗ"
            @select="onSelect('src_point_id', $event)"
            :isPlaceholderDisabled="true"
        />
      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Место назначения</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ getPointDescription(shipment.point_dst) }}
        </p>
        <Select
            v-else
            :options="pointSelect"
            :value="shipment.dts_point_id"
            placeholder="Выберите ПВЗ"
            @select="onSelect('dts_point_id', $event)"
            :isPlaceholderDisabled="true"
        />
      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Текущее место</h2>
        <!-- только если статус 80 ??-->
        <p v-if="mode === 'read' || !this.isAdmin" class="shipment-grid-text">
          {{ getPointDescription(shipment.point_cur) }}
        </p>
        <Select
            v-if="this.isAdmin && mode !== 'read'"
            :options="pointSelect"
            :value="shipment.cur_point_id"
            placeholder="Выберите ПВЗ"
            @select="onSelect('cur_point_id', $event)"
            :isPlaceholderDisabled="true"
        />
      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Ячейка</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ shipment.ceil?.name ?? '-' }}
        </p>
        <select v-else :value="shipment.ceil_id" @input="onInput('ceil_id', $event)"
                class="form-select modal-add-worker-form-style-select">
          <option
              v-for="item in ceils"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </select>
      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Комментарий</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ shipment.comment }}
        </p>
        <input
            v-else
            class="form-control style-profile-all-input"
            type="text"
            :value="shipment.comment"
            @input="onInput('comment', $event)"
        />
      </div>

      <div class="block-shadow">
        <h2 class="title-small-shipment">Контрольная дата доставки</h2>
        <p v-if="mode === 'read'" class="shipment-grid-text">
          {{ control_delivery_date }}
        </p>
        <input
            v-else
            class="form-control style-profile-all-input"
            type="text"
            :value="shipment.control_delivery_date"
            @input="this.onInput('control_delivery_date', $event)"
        />
      </div>

      <!-- TODO back link -->
      <a class="block-shadow block--clickable block-red" v-if="shipment.parent_id"
         :href="`/shipments/${shipment.parent_id}`">
        <h2 class="title-small-shipment">Возврат заказа</h2>
        <div class="shipment-grid-text">{{ shipment.parent_id }}</div>
      </a>
      <a class="block-shadow block--clickable " v-if="shipment.return_shipment"
         :href="`/shipments/${shipment.return_shipment.id}`">
        <h2 class="title-small-shipment">Возвратный заказ</h2>
        <div class="shipment-grid-text">{{ shipment.return_shipment.id }}</div>
      </a>
    </div>

    <div class="shipment-grid-container--admin-btns" v-if="canEdit">
      <div v-if="mode === 'read'" class="block-shadow--btn">
        <button
            class="button-edit"
            @click.prevent="$emit('onEdit')"
            :disabled="loading"
        ></button>
      </div>
      <div v-if="mode === 'edit'" class="block-shadow--btn">
        <button
            class="button-ready"
            @click.prevent="onSave"
            :disabled="loading"
        ></button>
      </div>
      <div v-if="mode === 'edit'" class="block-shadow--btn">
        <button
            class="button-delete"
            @click.prevent="$emit('onDelete')"
            :disabled="loading"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "src/components/userforms/Select.vue";

import useVuelidate from "@vuelidate/core";
import {minValue, numeric, required} from "@vuelidate/validators";
import {getDstClientFio, getSrcClientFio} from "@/store/helper";
import {POINT_STATUS_CLOSED, USER_ROLE_ADMIN, USER_ROLE_SUPER} from "@/constants";
import {mapGetters} from "vuex";

export default {
  name: "EditDetail",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: "read",
    },
    loading: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  components: {Select},
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  watch: {
    mode(value) {
      if (value === 'edit' && !this.points.length) {
        this.$store.dispatch("points/getList");
      }

      if (value === 'edit' && !this.ceils.length) {
        this.$store.dispatch("ceils/getList");
      }
    }
  },
  validations() {
    return {
      shipment: {
        status: {required, numeric, minValue: minValue(0)},
        src_fio: {},
        dst_fio: {},
        src_point_id: {required, numeric, minValue: minValue(0)},
        dts_point_id: {required, numeric, minValue: minValue(0)},
        cur_point_id: {},
        ceil_id: {},
        control_delivery_date: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      roleId: 'user/role_id',
    }),
    ceils() {
      return this.$store.state.ceils.list;
    },
    points() {
      return this.$store.state.points.list;
    },
    pointSelect() {
      return this.points.map((item) => {
        let title = item.name;

        if (title.startsWith('г ')) {
          title = title.slice(2);
        }

        return {value: item.id, title};
      }).sort((a, b) => a.title.localeCompare(b.title));
    },
    isAdmin() {
      return [USER_ROLE_ADMIN, USER_ROLE_SUPER].includes(this.roleId);
    },
    statusSelect() {
      return this.$store.getters["shipments/statusOptions"];
    },
    statusNameById() {
      return this.$store.getters["shipments/statusNameById"](
          this.shipment.status
      );
    },
    toBeStatusNameById() {
      return this.shipment.to_be_status !== null
          ? this.$store.getters["shipments/statusNameById"](this.shipment.to_be_status)
          : '-';
    },
    backendErrors() {
      return this.$store.state.shipments.errors.updateItem;
    },
    src_fio() {
      return getSrcClientFio(this.shipment);
    },
    dst_fio() {
      return getDstClientFio(this.shipment);
    },
    control_delivery_date() {
      let value = this.shipment.control_delivery_date;

      if (!value) return '-';

      if (!(value instanceof Date)) {
        value = new Date(value);
      }

      const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
      return value.toLocaleDateString('ru-RU', options).replace(/\//g, '.');
    }
  },
  methods: {
    getPointDescription(point) {
      if (!point) {
        return '-';
      }

      if (point.status === POINT_STATUS_CLOSED) {
        return `${point.name} (закрыт)`;
      }

      return point.name ?? '-';
    },
    onInput(field, {target}) {
      this.v$.shipment[field]?.$touch();
      this.$emit("onInput", {[field]: target.value});
    },
    onSelect(field, val) {
      this.v$.shipment[field].$touch();
      this.$emit("onInput", {[field]: val});
    },
    async onSave() {
      if (this.loading) return;

      this.v$.$touch();
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.v$.$reset();
        this.$emit("onSave");
      }
    },
  },
  mounted() {
    if (this.mode === 'edit' && !this.ceils.length) {
      this.$store.dispatch("ceils/getList");
    }
    if (this.mode === 'edit' && !this.points.length) {
      this.$store.dispatch("points/getList");
    }
  },
};
</script>
<style scoped>
.block-red {
  background: rgba(250, 137, 137, 0.4);
}

.block--clickable {
  text-decoration: none;
}
</style>
