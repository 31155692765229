import News from "./News.vue";

export const newsRoutes = [
  {
    path: "/news",
    component: News,
    name: "news",
    meta: { title: "Новости" },
  },
];
