import {
  authentication,
  register,
  restore,
  getAll,
  getAuthUser,
  readItem,
  updateItem,
  deleteItem,
} from "src/api/user";
import { USERS_PAGE_LIMIT, USER_STATUS_VALUES } from "../constants";

function parseStatus(status) {
  let error = "unknown_error";
  switch (status) {
    case 500:
      error = "server_error";
      break;
    case 401:
    case 404:
      error = "credentials_error";
  }
  return error;
}

const storeToken =
  localStorage.getItem("auth") || sessionStorage.getItem("auth");

import { generatePassword } from "src/helpers/generators";

import router from "@/router";

const store = {
  namespaced: true,
  state() {
    return {
      form: {
        password: "",
        email_adress: "",
        remember: false,
      },
      token: storeToken,
      resetError: "",
      showReset: false,
      list: [],
      user: undefined,
      newUser: undefined,
      userLoadedPromise: null,
      newUserTimestamp: 0, //to update users list with filters
      pagination: {
        totalPages: 1,
        totalItems: 0,
        perPage: 0
      },
      error: "",
      errors: {
        saveUser: {},
        getUsers: {},
      },
    };
  },
  getters: {
    currentUser(state) {
      return state?.user;
    },
    partners(state) {
      return state.user?.partners || [];
    },
    isAuthenticated(state) {
      return !!state.token;
    },
    role_id(state) {
      return state.user ? state.user.role_id : -1;
    },
    points(state) {
      if (state.user) {
        return state.user.points;
      }
      return [];
    }
  },
  actions: {
    async getAuthUser(context) {
      // FIX user not ready
      context.state.userLoadedPromise = getAuthUser();

      try {
        const { status, data } = await context.state.userLoadedPromise;
        const user = data.data

        console.log(data);
        console.log(status);

        if (status === 200) {
          if (!user.points[0]) {
            user.points = []; // fix user.points=[null]
          }
          context.commit("SET_USER", user);
        }
      } catch ({ response = {} }) {
        context.commit("SET_ERROR", parseStatus(response.status));
      }
    },
    async authenticate(context) {
      const remember = context.state.form.remember;
      try {
        const { status, data } = await authentication(context.state.form);
        if (status === 200) {
          const { token } = data;

          if (remember) {
            localStorage.setItem("auth", token);
            sessionStorage.removeItem("auth");
          } else {
            sessionStorage.setItem("auth", token);
            localStorage.removeItem("auth");
          }

          context.commit("SET_TOKEN", { token });

          context.commit("SET_FORM", {
            password: "",
            email_adress: "",
            remember: false,
          });

          await context.dispatch("getAuthUser");
        }
      } catch ({ response = {} }) {
        context.commit("SET_ERROR", parseStatus(response.status));
      }
    },
    async restore(context) {
      const { email_adress } = context.state.form;
      try {
        const response = await restore({ email_adress });
        const { status } = response;
        if (status === 200) {
          context.commit("CLEAR_ERROR");
        }
      } catch ({ response = {} }) {
        context.commit("SET_RESET_ERROR", parseStatus(response.status));
      }
    },
    logout(context) {
      localStorage.removeItem("auth");
      sessionStorage.removeItem("auth");
      context.commit("RESET_TOKEN");
      context.commit("SET_TITLE", "", { root: true });
      context.commit("SET_USER", null);
      context.state.userLoadedPromise = null;
      router.push("/");
    },
    async getUsers(context, filters = {}) {
      context.commit("SET_ERRORS", {
        key: "getUsers",
        val: {},
      });

      const filter = {
        items: USERS_PAGE_LIMIT,
        page: filters.page || 1,
      };
      if (filters.status > -1) {
        filter.status = USER_STATUS_VALUES[filters.status];
      }
      if (filters.role > -1) {
        filter.role = filters.role;
      }
      if (filters.region > -1) {
        filter.region_id = filters.region;
      }
      if (filters.search) {
        filter.search = filters.search;
      }
      if(filters.point_id) {
        filter.point_id = filters.point_id;
      }
      try {
        const resp = await getAll(filter);
        context.commit("SET_USER_LIST", resp.data);

        context.commit("SET_PAGINATION", {
          key: "totalPages",
          val: resp.headers["last_page"] * 1,
          totalItems: resp.headers["total"],
          perPage: resp.headers["per_page"]
        });
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getUsers",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getUsers",
            val: { error: "Ошибка" },
          });
        }
      }
    },
    async save(context, payload) {
      context.commit("SET_ERRORS", {
        key: "saveUser",
        val: {},
      });

      const user = { ...payload };
      try {
        user.login = user.email_adress;
        user.language = "ru";

        if (!user.id) {
          user.registration_date = new Date();
          user.password_reset_token = "reset-token";
          user.password = generatePassword();
        }

        const action = user.id
          ? () => updateItem(user.id, user)
          : () => register(user);
        const { data } = await action();
        if (!user.id) {
          context.commit("USER_CREATED");
          context.commit("SET_NEW_USER_TIMESTAMP");
        }
        return data;
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "saveUser",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "saveUser",
            val: { error: "Ошибка" },
          });
        }
      }
    },
    async read(context, id) {
      try {
        const { status, data } = await readItem(id);
        if (status === 200) {
          context.commit("SET_ITEM", data);
        }
      } catch ({ message, response = {} }) {
        context.commit("SET_ERROR", parseStatus(response.status) || message);
      }
    },
    async delete(context, id) {
      try {
        const { status, data } = await deleteItem(id);
        if (status === 200) {
          context.commit("CLEAR_ITEM", data);
        }
      } catch ({ message, response = {} }) {
        // context.commit(
        //   "SET_CREATE_ERROR",
        //   parseStatus(response.status) || message
        // );
      }
    },
  },
  mutations: {
    SET_PAGINATION(state, payload) {
      state.pagination[payload.key] = payload.val;
      state.pagination.totalItems = payload.totalItems;
      state.pagination.perPage = payload.perPage;
    },
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
    USER_CREATED(state) {
      state.newUser = undefined;
    },
    CREATE_USER(state, user) {
      state.newUser = user;
    },
    UPDATE_USER(state, [key, value]) {
      state.newUser[key] = value;
    },
    SET_NEW_USER_TIMESTAMP(state) {
      state.newUserTimestamp = "" + Date.now();
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, { token }) {
      state.token = token;
    },
    RESET_TOKEN(state) {
      state.token = "";
    },
    CLOSE_RESET(state) {
      state.showReset = false;
    },
    OPEN_RESET(state) {
      state.showReset = true;
    },
    SET_ERROR(state, code) {
      state.error = code;
    },
    SET_RESET_ERROR(state, code) {
      state.resetError = code;
    },
    CLEAR_ERROR(state) {
      state.resetError = "";
      state.error = "";
    },
    SET_USER_LIST(state, users) {
      state.list = users;
    },
    SET_FORM(state, payload) {
      state.form = { ...state.form, ...payload };
    },
  },
};

export default store;
