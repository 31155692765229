<template>
  <div style="padding-top: 30px;">
    <div style="display: inline">
      <label class="input-file" style="padding-right: 30px;">
        <span class="input-file-text" type="text">{{ fileName }}</span>
        <input type="file" name="file" @change="onFileChanged($event)">
        <span class="input-file-btn">Выберите файл</span>
      </label>
    </div>

    <div style="display: inline">
      <BaseSelect style="padding-right: 30px;"
          v-model="state.status"
          :options="statusSelect"
          :placeholder="titles.status"
      />

      <span v-if="v$.status.$error">{{ v$.status.$errors[0].$message }}</span>
    </div>

    <div>
      <el-checkbox style="padding-right: 30px;" v-model="state.setStatusReturn">
        Поставить статус для возвратов заказов
      </el-checkbox>

      <span v-if="v$.setStatusReturn.$error">{{ v$.setStatusReturn.$errors[0].$message }}</span>
    </div>

    <button
        :disabled="!fileName || !state.status || (typeof state.setStatusReturn !== 'boolean')"
        class="edit-worker-modal-button-save"
        type="button"
        @click="onUpdateShipments"
    >
      Сохранить изменения
    </button>


    <div v-if="state.failShipments.length > 0" style="padding-top: 10px;">
      <p>Не удалось проставить статусы для заказов</p>
      <ol>
        <li v-for="(item, index) in state.failShipments" :key="index">
          {{ index + 1 }}) {{ item }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>

import BaseSelect from '@/components/toolbar/BaseSelect';
import useVuelidate from "@vuelidate/core";
import {ElMessageBox, ElNotification} from "element-plus";
import {helpers, required} from '@vuelidate/validators'
import {reactive, computed} from 'vue'
import {
  SHIPMENT_DESTROYED_BY_AVITO_REQUEST,
  SHIPMENT_ISSUED,
  SHIPMENT_LOST_BY_CARRIER,
  SHIPMENT_STATUS_VALUES
} from "@/constants";
import {multiChangeStatus} from "src/api/shipments";


export default {
  name: "ShipmentsSetStatus",
  components: {
    BaseSelect,
  },
  setup() {
    const state = reactive({
      status: null,
      setStatusReturn: false,
      failShipments: [],
    })

    const rules = computed(() => {
      return {
        status: {required: helpers.withMessage('Это поле обязательно для заполнения', required)},
        setStatusReturn: {required: helpers.withMessage('Это поле обязательно для заполнения', required)},
      }
    })

    const v$ = useVuelidate(rules, state);

    return {
      state,
      v$
    }
  },
  data() {
    return {
      fileName: '',
      headerTitle: 'Массовая установка статусов заказа',
      titles: {
        status: 'Статусы'
      },
      statusSelect: [
        {value: SHIPMENT_ISSUED, title: 'Выдано'},
        {value: SHIPMENT_DESTROYED_BY_AVITO_REQUEST, title: "Утилизация"},
        {value: SHIPMENT_LOST_BY_CARRIER, title: "Утеря"},
      ],
    };
  },
  mounted() {
    this.$store.commit("SET_TITLE", this.headerTitle);
  },
  computes: {
    statusSelect() {
      return this.statusSelect.map((item) => {
        return {value: item.value, title: item.title};
      });
    },
  },
  methods: {
    onFileChanged(e) {
      this.fileName = '';
      this.file = e.target.files[0];
      if (this.file.name.length > 30) {
        this.fileName = this.file.name.substr(0, 30) + '...';
      } else {
        this.fileName = this.file.name;
      }

    },

    async onUpdateShipments() {
      if (this.file == null) {
        return ElNotification({
          title: 'Не выбран файл',
          message: 'Не выбран файл',
          type: 'error',
        });
      }

      await this.v$.$validate();

      if (!this.v$.$error) {
        let data = new FormData();

        await ElMessageBox.confirm(
            'Внимание! ' +
            'Изменения нельзя будет отменить. ' +
            'Поставить статус ' + SHIPMENT_STATUS_VALUES[this.state.status] + ' заказам из файла?', 'Подтверждение действия',
            {
              confirmButtonText: 'Завершить',
              cancelButtonText: 'Отменить',
              cancelButtonClass: 'el-button--info'
            },
        )

        data.append('attach', this.file);
        data.append('status', this.state.status);
        data.append('set_status_to_return', this.state.setStatusReturn ? '1' : '0');

        await multiChangeStatus(data).then((data) => {
          this.state.failShipments = data.data.filter((item) => {
            return item.includes('fail');
          })

          return ElNotification({
            title: 'Статусы обновлены',
            message: 'Статусы обновлены',
            type: 'success',
          });
        });
      } else {
        return ElNotification({
          title: 'Форма не валидна',
          message: 'Форма не валидна',
          type: 'error',
        });
      }
    }
  }
}
</script>

<style scoped>
.input-file {
  position: relative;
  display: inline-block;
}

.input-file-text {
  padding: 0 10px;
  line-height: 40px;
  font-size: 12px;
  text-align: left;
  height: 40px;
  display: block;
  float: left;
  box-sizing: border-box;
  width: 250px;
  border-radius: 6px 0px 0 6px;
  border: 1px solid #ddd;
}

.input-file-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  vertical-align: middle;
  color: rgb(255 255 255);
  text-align: center;
  border-radius: 0 4px 4px 0;
  background-color: #419152;
  line-height: 22px;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;
}

.input-file input[type=file] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

/* Focus */
.input-file input[type=file]:focus + .input-file-btn {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover/active */
.input-file:hover .input-file-btn {
  background-color: #59be6e;
}

.input-file:active .input-file-btn {
  background-color: #2E703A;
}

/* Disabled */
.input-file input[type=file]:disabled + .input-file-btn {
  background-color: #eee;
}
</style>
