<template>
    <div class="hover-notification-items main-shadow">
        <div class="hover-notification-image">
            <img src="../../assets/img/notification_button.svg" alt="notification">
            <span class="hover-notification-quantity">3</span>
        </div>
        <div class="hover-notification-description">
            <a class="hover-notification-items-title" href="#">{{ item.title }}</a>
            <p class="hover-notification-items-text">{{ item.text }}
            </p>
            <p class="hover-notification-items-time">{{ time }}</p>
        </div>
    </div>
</template>

<script>
import dateFormat from "dateformat"
import { NOTIFICATION_TIME_FORMAT } from 'src/constants'
import { NotificationType } from 'src/types'


export default {
    name: 'NotifyDropdownItem',
    props: {
        item: NotificationType,
    },
    computed: {
        time() {
            return dateFormat(this.item.time, NOTIFICATION_TIME_FORMAT)
        },
    },
    setup() {
        
    },
}
</script>