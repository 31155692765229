import PpActs from "@/views/pp-acts/PpActs.vue";
import PpAct from "@/views/pp-acts/PpAct.vue";

export const ppActsRoutes = [
    {
        path: "/pp-acts",
        component: PpActs,
        name: "ppActs",
        meta: {title: "Акты приёма-передачи"},
    },
    {
        path: "/pp-acts/:uuid",
        component: PpAct,
        name: "ppAct",
        meta: {
            isHeaderBackLink: true,
            title: "Акт приёма-передачи"
        },
    },
];
