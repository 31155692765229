import AcceptShipments from "./AcceptShipments.vue";
import AcceptShipmentsCreate from "./AcceptShipmentsCreate.vue";
import RegistrationShipment from "./RegistrationShipment.vue";
import MultiAcceptShipments from "@/views/accept/MultiAcceptShipments.vue";

export const acceptRoutes = [
  {
    path: "/accept",
    component: AcceptShipments,
    name: "acceptShipments",
    // meta: { title: "Прием отправлений" },
  },
  {
    path: "/multi-accept",
    component: MultiAcceptShipments,
    name: "multiAccept",
  },
  {
    path: "/accept/create",
    component: AcceptShipmentsCreate,
    name: "acceptShipmentsCreate",
    meta: { isHeaderBackLink: true },
  },
  {
    path: "/accept/:id",
    component: RegistrationShipment,
    name: "registration-shipment",
    meta: { title: 'Оформление отправления', isHeaderBackLink: true },
  },
];
