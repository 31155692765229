<template>
  <Datepicker
    :value="value"
    :enableTimePicker="false"
    :placeholder="placeholder"
    :format="format"
    locale="ru"
    inputClassName="form-control custom__dp custom__dp--shadow date-picker__input"
    type="datepicker"
    hideInputIcon
    selectText="Выбрать"
    cancelText="Отмена"
    class="date-picker"
    @update:modelValue="$emit('onUpdate', $event)"
    @clear="$emit('onUpdate', $event)"
  />
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

export default {
  name: "DatePicker",
  components: { Datepicker },
  props: {
    value: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: {
      type: String,
      default: 'Весь период',
    }
  },
  data() {
    return {
      format: "dd-MM-yyyy",
    };
  },
};
</script>
