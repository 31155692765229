<template>
  <form class="ModalForm form-send-mail" novalidate>
    <h3>Регистрация пользователя</h3>
    <div class="modal_form-item">
      <label class="modal_item_label">ФИО</label>
      <input
        class="form-control emptyCheck style-profile-all-input"
        :class="{
          'is-invalid':
            (v$.user.last_name.$dirty && v$.user.last_name.$invalid) ||
            backendErrors.last_name,
        }"
        type="text"
        placeholder="Фамилия"
        :value="user.last_name"
        @input="onInput('last_name', $event)"
      />
      <div
        v-if="
          (v$.user.last_name.$dirty && v$.user.last_name.$invalid) ||
          backendErrors.last_name
        "
        class="invalid-feedback"
      >
        <p
          v-if="v$.user.last_name.$dirty && v$.user.last_name.$invalid"
          class="invalid-feedback-text"
          >Неверный формат</p
        >
        <p v-if="backendErrors.last_name" class="invalid-feedback-text">{{
          backendErrors.last_name[0]
        }}</p>
      </div>
    </div>

    <div class="modal_form-item">
      <input
        class="form-control emptyCheck style-profile-all-input"
        :class="{
          'is-invalid':
            (v$.user.first_name.$dirty && v$.user.first_name.$invalid) ||
            backendErrors.first_name,
        }"
        type="text"
        placeholder="Имя"
        :value="user.first_name"
        @input="onInput('first_name', $event)"
      />
      <div
        v-if="
          (v$.user.first_name.$dirty && v$.user.first_name.$invalid) ||
          backendErrors.first_name
        "
        class="invalid-feedback"
      >
        <p
          v-if="v$.user.first_name.$dirty && v$.user.first_name.$invalid"
          class="invalid-feedback-text"
          >Неверный формат</p
        >
        <p v-if="backendErrors.first_name" class="invalid-feedback-text">{{
          backendErrors.first_name[0]
        }}</p>
      </div>
    </div>

    <div class="modal-item">
      <input
        class="form-control emptyCheck style-profile-all-input"
        :class="{
          'is-invalid':
            (v$.user.middle_name.$dirty && v$.user.middle_name.$invalid) ||
            backendErrors.middle_name,
        }"
        type="text"
        placeholder="Отчество"
        :value="user.middle_name"
        @input="onInput('middle_name', $event)"
      />
      <div
        v-if="
          (v$.user.middle_name.$dirty && v$.user.middle_name.$invalid) ||
          backendErrors.middle_name
        "
        class="invalid-feedback"
      >
        <p
          v-if="v$.user.middle_name.$dirty && v$.user.middle_name.$invalid"
          class="invalid-feedback-text"
          >Неверный формат</p
        >
        <p v-if="backendErrors.middle_name" class="invalid-feedback-text">{{
          backendErrors.middle_name[0]
        }}</p>
      </div>
    </div>

    <div class="modal_form-item">
      <label class="modal_item_label">Роль</label>
      <Select
        :class="{
          'is-invalid':
            (v$.user.role_id.$dirty && v$.user.role_id.$invalid) ||
            backendErrors.role_id,
        }"
        :options="roleSelect"
        :value="-1"
        placeholder="Выберите роль"
        @select="onSelect('role_id', $event)"
      />
      <div
        v-if="
          (v$.user.role_id.$dirty && v$.user.role_id.$invalid) ||
          backendErrors.role_id
        "
        class="invalid-feedback"
      >
        <p
          v-if="v$.user.role_id.$dirty && v$.user.role_id.$invalid"
          class="invalid-feedback-text"
          >Неверный формат</p
        >
        <p v-if="backendErrors.role_id" class="invalid-feedback-text">{{
          backendErrors.role_id[0]
        }}</p>
      </div>
    </div>

    <div class="modal_form-item">
      <label class="modal_item_label">Пункт выдачи</label>
      <Select
        :class="{
          'is-invalid':
            (v$.user.point_id.$dirty && v$.user.point_id.$invalid) ||
            backendErrors.point_id,
        }"
        :options="pointSelect"
        :value="-1"
        placeholder="Не назначать"
        @select="onSelect('point_id', $event)"
      />
      <div
        v-if="
          (v$.user.point_id.$dirty && v$.user.point_id.$invalid) ||
          backendErrors.point_id
        "
        class="invalid-feedback"
      >
        <p
          v-if="v$.user.point_id.$dirty && v$.user.point_id.$invalid"
          class="invalid-feedback-text"
          >Неверный формат</p
        >
        <p v-if="backendErrors.point_id" class="invalid-feedback-text">{{
          backendErrors.point_id[0]
        }}</p>
      </div>
    </div>

    <div class="modal_form-item">
      <label class="modal_item_label">Email</label>
      <input
        class="form-control emptyCheck style-profile-all-input"
        :class="{
          'is-invalid':
            (v$.user.email_adress.$dirty && v$.user.email_adress.$invalid) ||
            backendErrors.email_adress,
        }"
        type="text"
        placeholder="Email"
        :value="user.email_adress"
        @input="onInput('email_adress', $event)"
      />
      <div
        v-if="
          (v$.user.email_adress.$dirty && v$.user.email_adress.$invalid) ||
          backendErrors.email_adress
        "
        class="invalid-feedback"
      >
        <p
          v-if="v$.user.email_adress.$dirty && v$.user.email_adress.$invalid"
          class="invalid-feedback-text"
          >Неверный формат</p
        >
        <p v-if="backendErrors.email_adress" class="invalid-feedback-text">{{
          backendErrors.email_adress[0]
        }}</p>
      </div>
    </div>

    <div class="modal_form-item">
      <label class="modal_item_label">Телефон</label>
      <input
        class="form-control emptyCheck style-profile-all-input"
        :class="{
          'is-invalid':
            (v$.user.phone_primary.$dirty && v$.user.phone_primary.$invalid) ||
            backendErrors.phone_primary,
        }"
        type="text"
        placeholder="Телефон"
        :value="user.phone_primary"
        @input="onInput('phone_primary', $event)"
      />
      <div
        v-if="
          (v$.user.phone_primary.$dirty && v$.user.phone_primary.$invalid) ||
          backendErrors.phone_primary
        "
        class="invalid-feedback"
      >
        <p
          v-if="v$.user.phone_primary.$dirty && v$.user.phone_primary.$invalid"
          class="invalid-feedback-text"
          >Неверный формат</p
        >
        <p v-if="backendErrors.phone_primary" class="invalid-feedback-text">{{
          backendErrors.phone_primary[0]
        }}</p>
      </div>
    </div>
    <div class="modal_form-item modal_hgroup_2">
      <div class="modal_form-item modal_hgroup_2-item">
        <Button modifyer="users-item" class="secondary" @click="cancel"
          >Отмена</Button
        >
      </div>
      <div class="modal_form-item modal_hgroup_2-item">
        <Button modifyer="users-item" @click="save" :disabled="loading"
          >Сохранить</Button
        >
      </div>
    </div>
    <div v-if="backendErrors.error" class="is-invalid">
      <div class="invalid-feedback">
        <p class="invalid-feedback-text">{{ backendErrors.error }}</p>
      </div>
    </div>
  </form>
</template>

<script>
import Button from "src/components/base/Button.vue";
import Select from "src/components/userforms/Select.vue";

import useVuelidate from "@vuelidate/core";
import { required, email, numeric, minValue } from "@vuelidate/validators";

export default {
  props: {
    user: Object,
  },
  components: {
    Button,
    Select,
  },
  data() {
    return {
      loading: false,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      user: {
        last_name: { required },
        first_name: { required },
        middle_name: { required },
        role_id: { required, minValue: minValue(0) },
        point_id: {},
        email_adress: { required, email },
        phone_primary: { required, numeric },
      },
    };
  },
  computed: {
    backendErrors() {
      return this.$store.state.user.errors.saveUser;
    },
    roles() {
      return this.$store.state.dicts.userRoles;
    },
    points() {
      return this.$store.state.points.list;
    },
    roleSelect() {
      return this.roles.map((item) => {
        return { value: item.id, title: item.authority };
      });
    },
    pointSelect() {
      return this.points.map((item) => {
        return { value: item.id, title: item.name };
      });
    },
  },
  methods: {
    cancel() {
      this.$emit("user:cancel");
    },
    async save() {
      if (this.loading) return;

      this.v$.$touch();
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.loading = true;
        this.v$.$reset();
        await this.$store.dispatch("user/save", this.user);
        this.loading = false;
      }
    },
    onInput(field, { target }) {
      this.v$.user[field].$touch();
      this.$emit("user:change", [field, target.value]);
    },
    onSelect(field, val) {
      this.v$.user[field].$touch();
      this.$emit("user:change", [field, val]);
    },
  },
};
</script>

<style lang="scss">
.modal {
  &_form-item {
    width: 100%;
    padding: 0 0 10px 0;
    position: relative;
  }
  &_item_label {
    margin: 10px -5px;
  }

  &_hgroup_2 {
    &-item {
      display: inline-block;
      width: 48%;
      &:first-child {
        margin-right: 4%;
      }
    }
  }
  &_hgroup_3 {
    &-item {
      display: inline-block;
      width: 32%;
      margin-right: 2%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
