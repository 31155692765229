<template>
    <div class="modal__wrapper" @click.self="close">
        <form class="p-4" @submit.prevent="sendReason">
            <el-select v-model="value" placeholder="Выбрать причину" class="mb-3 w-full" size="large">
                <el-option
                v-for="item in reasonsList"
                :key="item.id"
                :label="item.reason"
                :value="item.id"
                />
            </el-select>
            <div class="mb-3" v-if="value">
                <b>Причина:</b> {{ this.reasonItem.reason }}
            </div>
            <div class="flex">
                <button class="el-button el-button--primary el-button--default" :disabled="!value">Отказать</button>
                <button type="button" class="el-button el-button--default" @click="close">Отмена</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'ModalRefuse',
    props: [
        'from',
        'has-parent'
    ],
    computed: {
        reasonItem() {
            return (this.reasonsList.find(item => item.id == this.value) || {});
        },
        reasonsList() {
            return this.$store.state.refuse.reasonsList;
        }
    },
    data() {
        return {
            value: ''
        }
    },
    mounted() {
        // в зависимости от контекста отправляем разное название события список событий см в api/refusalResoans.js
        const reasonFrom = {
            accept: 'GOODS_CHECK_FAILED',
            give: 'REFUSAL_BY_RECEIVER',
        };

        let reasonEventName = reasonFrom[this.from];

        if (reasonEventName == 'REFUSAL_BY_RECEIVER' && this.hasParent) {
            reasonEventName = 'RETURN_REFUSAL_BY_SENDER';
        }
        
        this.$store.dispatch('refuse/getReasons', reasonEventName);
    },
    methods: {
        sendReason() {
            if (!this.value) return;
            this.$store.dispatch('shipments/cancelled', {
                id: +this.$route.params.id,
                reasonId: +this.value
            });
            this.close();
            this.$router.go(0);
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
    b{
        font-weight: 700;
    }
    .modal__wrapper{
        display: flex;
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    form{
        max-height: 95vh;
        background: #fff;
        width: 640px;
        margin: auto;
        box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
        border-radius: 10px;
    }
    .w-full{
        width: 100%;
    }
</style>
