<template>
    <div class="language-switcher">
        <a class="active-language" :class="current" href="#">{{ current }}</a>
        <div v-if="languages.length > 0" class="line"></div>
        <a v-for="item in languages" :class="item" href="#" v-bind:key="item">{{ item }}</a>
    </div>
</template>

<script>
    export default {
        name: 'Lang',
        props: {
            current: String,
            list: Array,
        },
        computed: {
            languages() {
                return this.list.filter((lang) => lang !== this.current)
            },
        },
    }
</script>