<template>
  <div class="shipment-history-info-container">
    <h2 class="shipment-history-info-title">История отправления</h2>
    <div class="shipment-history-orders" v-if="history && history.length">
      <ul class="shipment-history-table">
        <li class="shipment-history-table-items shipment-history-item-status-width">
          Статус
        </li>
        <li class="px-2 shipment-history-table-items shipment-history-item-date-width">
          Дата
        </li>
        <li class="shipment-history-table-items shipment-history-item-name-width">
          Комментарий
          </li>
      </ul>
      <ul class="shipment-history-table" v-for="item in history" :key="item.id">
        <li class="shipment-history-table-items shipment-history-item-status-width">
          {{ statusById(item.status) }}
          </li>
        <li class="px-2 shipment-history-table-items shipment-history-item-date-width">
          {{ dateFormat(item.status_date) }}
        </li>
        <li class="shipment-history-table-items shipment-history-item-name-width">
          {{ getComment(item.comment) }}
        </li>
      </ul>
     <!-- <button class="shipment-history-dropdown">Развернуть полностью</button>-->
    </div>
    <div v-else>Нет данных</div>
  </div>
</template>

<script>
import dateFormat from "dateformat";
import { SHIPMENT_HISTORY_DATE_FORMAT } from "src/constants";

export default {
  name: "ShipmentHistory",
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    history() {
      return this.$store.state.shipments.history;
    },
  },
  created() {
    this.$store.dispatch('shipments/getHistory', this.shipment.id);
  },
  methods: {
    statusById(id) {
      return this.$store.getters["shipments/statusNameById"](id);
    },
    dateFormat(date) {
      return dateFormat(date, SHIPMENT_HISTORY_DATE_FORMAT);
    },
    getComment(comment) {
      const jsonIndex = comment.indexOf('{');

      if (jsonIndex >= 0) {
        const jsonObject = comment.slice(jsonIndex)

        return `${comment.slice(0, jsonIndex)} ${JSON.stringify(JSON.parse(jsonObject))}`
      }

      return comment
    }
  }
};
</script>
