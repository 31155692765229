<template>
<div v-if="points.length">
  <div class="container-search d-flex justify-content-between mb-4">
      <el-date-picker
        class="input--date-range"
        v-model="dateRange"
        type="daterange"
        start-placeholder="Дата от"
        end-placeholder="Дата до"
        @change="loadData"
      />
      <BaseSelect
        style="width: 544px"
        v-model="selectedPoints"
        :options="points"
        multiple
        collapse-tags
        placeholder="ПВЗ отправления"
        @search="loadData"
      />
  </div>
  
  <h2 class="mb-2 fw-bold">Виджеты</h2>
  <div class="d-flex gap-4 mb-4" v-if="widgets">
    <div class="widgets">
      <!-- TODO -->
      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.averageKeepTime.value}}</div>
          {{widgets.averageKeepTime.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/clock.svg" alt="" loading="lazy">
        </div>
      </div>
      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.takenShipmentsCount.value}}</div>
          {{widgets.takenShipmentsCount.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/accepted_order.svg" alt="" loading="lazy">
        </div>
      </div>
      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.givenShipmentsCount.value}}</div>
          {{widgets.givenShipmentsCount.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/taked_orders.svg" alt="" loading="lazy">
        </div>
      </div>

      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.totalMoneyForMeaningfulActions.value}}</div>
          {{widgets.totalMoneyForMeaningfulActions.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/cash.svg" alt="" loading="lazy">
        </div>
      </div>
      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.returnedShipmentsPercent.value}}</div>
          {{widgets.returnedShipmentsPercent.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/return.svg" alt="" loading="lazy">
        </div>
      </div>
      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.numberOfUnacceptedFreights.value}}</div>
          {{widgets.numberOfUnacceptedFreights.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/unaccepted_order.svg" alt="" loading="lazy">
        </div>
      </div>

      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.numberOfUnclaimedShipments.value}}</div>
          {{widgets.numberOfUnclaimedShipments.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/unclaimed.svg" alt="" loading="lazy">
        </div>
      </div>
      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.numberOfOverdueShipments.value}}</div>
          {{widgets.numberOfOverdueShipments.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/return.svg" alt="" loading="lazy">
        </div>
      </div>
      <div class="widgets__item">
        <div>
          <div class="widgets__count">{{widgets.cancelledImShipmentsPercent.value}}</div>
          {{widgets.cancelledImShipmentsPercent.comment}}
        </div>
        <div class="widgets__icon">
          <img class="widgets__icon-image" src="@/assets/img/widgets-icons/cancellation_percentage.svg" alt="" loading="lazy">
        </div>
      </div>
    </div>

    <div class="widgets__item widget__item--aside" v-if="widgets">
      <div class="fw-bold text-start w-100">Загрузка ПВЗ</div>
      <svg height="228" width="228" viewBox="0 -10 20 20" style="position: relative; top: -28px">    
        <circle r="5" cx="10" cy="10" fill="none"
          stroke="#EFEFEF"
          stroke-width="17"
          stroke-dasharray="calc(100 * 31.42 / 200)"
          transform="rotate(-180, 10, 10)"/>
        <circle r="5" cx="10" cy="10" fill="none"
          stroke="#A6B0CF"
          stroke-width="17"
          :stroke-dasharray="`calc(${percentToDegree} * 31.42 / 360) 50`"
          transform="rotate(-180, 10, 10)"/>
          
        <path d="M 0 10 L 10 10.3 L 10 9.7 L 0 10" fill="#495057" :transform="`rotate(${percentToDegree}, 10, 10)`" />
        <circle r=".2" cx="10" cy="10" fill="#495057" />
      </svg>
      <div class="fw-bold mb-2">{{widgets.speedometr.current}} из {{widgets.speedometr.max }}</div>
      Осталось {{widgets.speedometr.percentage}}
    </div>

  </div>

  <!--<h2 class="mb-2 fw-bold">Графики</h2>
  
  <div class="d-flex mb-4" style="justify-content: space-between">
    <div class="chart__wrapper">
      <h2 class="mb-2 fw-bold" style="font-size: 14px; color: #556EE6">Прием и выдача заказов</h2>
      <ChartOrders type="line" :data="data1" />
    </div>
    
    <div class="chart__wrapper">
      <h2 class="mb-2 fw-bold" style="font-size: 14px; color: #556EE6">Обороты ПВЗ</h2>
      <ChartOrders type="bar" :data="data2" />
    </div>
  </div>

  <div class="chart__wrapper mb-4" style="width: 100%;">
    <h2 class="mb-2 fw-bold" style="font-size: 14px; color: #556EE6">Занятость работников</h2>
    <ChartOrders type="bar" :data="data3" />
  </div>

  <div class="chart__wrapper">
    <h2 class="mb-2 fw-bold" style="font-size: 14px; color: #556EE6">Доля операций интернет-магазина</h2>
    <ChartOrders type="pie" :labels="['Доли по обьему', 'Доли по выдаче']" :data="data4"/>
  </div>-->
</div>
<div class="confirm-age" v-else>
  Нет данных для расчета статистики
</div>
</template>

<style scoped>
  .widgets{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .widgets__item{
    box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
    border-radius: 4px;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    color: #A6B0CF;
    font-size: 14px;
    line-height: 140%;
  }
  .widgets__count{
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;
    color: #495057;
  }
  .widgets__count--red{
    color: #FA8989;
  }
  .widgets__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    background: #F8F8FB;
    border-radius: 64px;
    overflow: visible;
  }
  .widgets__icon-image {
    width: 44px;
    height: 44px;
  }

  .widget__item--aside{
    flex-direction: column;
    height: 282px;
    justify-content: center;
  }
  .widget__item--aside img {
    margin: 24px 0;
  }
  .chart__wrapper{
    padding: 12px;
    width: calc(50% - 10px);
    background: #fff;
    border-radius: 4px;
    box-shadow: 2px 2px 12px rgb(42 48 66 / 11%);
  }
</style>

<script>
import BaseSelect from '@/components/toolbar/BaseSelect';
// import ChartOrders from './ChartOrders.vue';

const today = new Date();
const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

export default {
  name: "Statistic",
  components: {
    BaseSelect,
    // ChartOrders
  },
  data() {
    return  {
      dateRange: [
        new Date(today - 604800000).toLocaleDateString('lt'),
        today.toLocaleDateString('lt')
      ],
      data1: {
        labels: months,
        datasets: [
          {
            label: 'Прием заказов',
            data: [12, 19, 3, 5, 2, 3, 1, 1, 12, 2, 5, 12, 8],
            borderColor: '#556EE6',
            backgroundColor: '#556EE6',
            fill: false,
          },
          {
            label: 'Выдача заказов',
            data: [11, 19, 2, 7, 1, 3, 3, 2, 15, 5, 2, 1, 1, 3],
            borderColor: '#FA8989',
            backgroundColor: '#FA8989',
            fill: false,
          },
        ]
      },
      data2: {
        labels: months,
        datasets: [
          {
            label: 'Агентское вознаграждение (руб)',
            data: [12, 19, 3, 5, 2, 3, 1, 1, 12, 2, 5, 12, 8],
            borderColor: 'rgba(85, 110, 230, 0.2)',
            backgroundColor: 'rgba(85, 110, 230, 0.2)',
            fill: false,
          },
          {
            label: 'Сумма выручки (руб)',
            data: [11, 19, 2, 7, 1, 3, 3, 2, 15, 5, 2, 1, 1, 3],
            borderColor: 'rgba(52, 195, 143, .2)',
            backgroundColor: 'rgba(52, 195, 143, .2)',
            fill: false,
          },
        ]
      },
      data3: {
        labels: months,
        datasets: [
          {
            label: 'Агентское вознаграждение (руб)',
            data: [2, 19, 3, 6, 1, 3, 2, 19, 3, 5, 2, 1, 1, 3],
            borderColor: '#556EE6',
            backgroundColor: '#556EE6',
            fill: false,
          },
          {
            label: 'Сумма выручки (руб)',
            data: [8, 18, 2, 5, 1, 3, 3, 2, 15, 2, 7, 1, 1, 3],
            borderColor: '#FA8989',
            backgroundColor: '#FA8989',
            fill: false,
          },
          {
            label: 'Сумма выручки (руб)',
            data: [3, 17, 8, 3, 1, 3, 3, 2, 15, 5, 2, 1, 1, 3],
            borderColor: '#34C38F',
            backgroundColor: '#34C38F',
            fill: false,
          },
          {
            label: 'Сумма выручки (руб)',
            data: [11, 16, 1, 8, 1, 3, 3, 2, 15, 5, 2, 1, 1, 3],
            borderColor: '#F1B44C',
            backgroundColor: '#F1B44C',
            fill: false,
          }
        ]
      },
      data4: {
        labels: ['Доли по приему', 'Доли по выдаче'],
        datasets: [
          {
            label: 'Dataset 1',
            data: [10, 19],
            borderColor: ['#556EE6', '#FA8989'],
            backgroundColor: ['#556EE6', '#FA8989']
          }
        ]
      },
    }
  },
  async created() {
    this.$store.commit("SET_TITLE", "Статистика");

    if (!this.user && this.$store.state.user.userLoadedPromise) {
      await this.$store.state.user.userLoadedPromise;
    }

    if (this.points.length) {
      this.selectedPoints = this.points.map(item => item.value);
    }
    this.loadData();
  },
  computed: {
    percentToDegree() {
      return (180 / 100 * (100 - parseInt(this.widgets.speedometr.percentage)));
    },
    user(){
      return this.$store.state.user.user;
    },
    widgets() {
      return this.$store.state.statistic.widgets;
    },
    points() {
      if (!this.user) return [];
      return (this.user.points || this.user.point ? [this.user.point] : []).map((item) => {
        return { value: item.id, title: item.name };
      });
    }
  },
  methods: {
    async loadData() {
      this.$store.dispatch("statistic/getWidgets", {
        from: this.dateRange[0],
        to: this.dateRange[1],
        points:  this.selectedPoints
      });
    }
  }
}
</script>
