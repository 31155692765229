<template>
  <!-- <h2>Уведомления</h2> -->
  <h2 class="pt-4">У вас нет прав доступа к данному разделу</h2>
</template>

<script>
export default {
  name: "Notifications",
  components: {},
};
</script>
