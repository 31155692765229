<template>
  <div class="shipments-search-table-container">
    <h2 class="title-style-small">{{ tableTitle }}</h2>
    <el-table v-loading="loading" :data="shipments" :scrollbar-always-on="'true'">
      <el-table-column label="ID" width="100px" fixed>
        <template #default="scope">
          <router-link :to="{ name: rowRouteName, params: { id: scope.row.id } }">{{ scope.row.id }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="№ отправления" width="150px"/>
      <el-table-column label="Отправитель" width="150px">
        <template #default="scope">
          {{ scope.row.client_src?.full_name }} {{ scope.row.client_src?.phone_primary}}
        </template>
      </el-table-column>
      <el-table-column label="Получатель" width="150px">
        <template #default="scope">
          {{ scope.row.client_dst?.full_name }} {{ scope.row.client_dst?.phone_primary}}
        </template>
      </el-table-column>
      <el-table-column label="Интернет-магазин" width="100px">
        Авито
      </el-table-column>
      <el-table-column label="Отправлено из" width="150px">
        <template #default="scope">
          {{ scope.row.point_src?.name }}
        </template>
      </el-table-column>
      <el-table-column label="Место назначения" width="150px">
        <template #default="scope">
          {{ scope.row.point_dst?.name }}
        </template>
      </el-table-column>
      <el-table-column label="Ячейка" width="150px">
        <template #default="scope">
          {{ getCeil(scope.row.ceil_id).name }}
        </template>
      </el-table-column>
      <el-table-column prop="sum" label="Объявленная стоимость" width="100px"/>
      <el-table-column label="Статус" width="200px">
        <template #default="scope">
          {{ shipmentsStatusNameById(scope.row.status) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default  {
  name: 'ShipmentsSearchTable',
  props: {
    shipments: Array,
    tableTitle: String,
    rowRouteName: String,
    loading: Boolean,
  },

  computed: {
    ...mapGetters({
      shipmentsStatusNameById: 'shipments/statusNameById',
      ceils: 'ceils/items',
      getCeil: 'ceils/itemById',
    }),
  },
  methods: {
    ...mapActions({
      getCeils: 'ceils/getList',
    }),
  },
  mounted() {
    if (this.ceils.length === 0) {
      this.getCeils();
    }
  },
};
</script>
<style>
.shipments-search-table-container {
  margin: 40px 0;
}

.shipments-search-table-container h2 {
  margin: 10px 0;
}

.shipments-search-table-container .el-table .cell {
  word-break: keep-all !important;
}
</style>
