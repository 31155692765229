import client from "./client";
import createCrud from "./createCrud";

async function authentication(user) {
  return await client.post("/sanctum/token", user);
}
async function register(user) {
  return await client.post("/sanctum/register", user);
}
async function restore(user) {
  return await client.post("/sanctum/restore", user);
}
async function getAuthUser() {
  return await client.post("/sanctum/user");
}

const { getAll, createItem, readItem, updateItem, deleteItem } = createCrud(
  client,
  "/users"
);

export {
  authentication,
  register,
  restore,
  getAuthUser,
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
};
