import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
  namespaced: true,
  actions: {
    addShipments(context, {id, body}) {
      console.log(body);
      return apiV2Service.put(`sendings/${id}/add-shipments`, body)
    },
  },
};
export default store;
