<template>
  <div class="deliveryItemsSent-items">
    <div class="deliveryItemsSent-item-container deliveryItemsSent-sum">
      <div class="deliveryItemsSent-sum-client-info">
        <p>Клиент: {{ dstFio }}</p>
        <p>Номер отправления: {{ shipment.number }}</p>
      </div>
      <div class="deliveryItemsSent-sum-total">
        <h2>Итого</h2>
        <div class="deliveryItemsSent-sum-total-items">
          <p class="deliveryItemsSent-sum-total-items-name">Сумма к оплате</p>
          <p class="deliveryItemsSent-sum-total-items-price"><!--{{ shipment.sum }}--> 0 ₽</p>
        </div>
      </div>
      <div class="deliveryItemsSent-sum-product-name">
        <template v-for="item in shipment.items" :key="item.id">
          <div class="deliveryItemsSent-sum-product-quantity">
            <div class="deliveryItemsSent-sum-product-quantity-text-container">
              <!-- Флаг has_partial_issuance определяет есть ли частичная выдача -->
              <el-checkbox v-if="shipment.has_partial_issuance && shipment.items.length > 1" v-model="selectedItems"
                           size="large" :label="item.id" checked="true"/>
              <div class="deliveryItemsSent-sum-product-quantity-text">
                <p>x{{ item.content_quantity }}</p>
                <p>{{ item.content_name }}</p>
              </div>
            </div>
            <p class="deliveryItemsSent-sum-product-text-line"></p>
            <p class="deliveryItemsSent-sum-product-quantity-price">{{ item.price }} Р</p>
          </div>
        </template>
        <!-- Если чето выбрали но не все элементы -->
        <div class="deliveryItemsSent-sum-product-payment-container"
             v-if="shipment.has_partial_issuance && selectedItems.length && selectedItems.length != shipment.items.length">
          <h2>Причина отказа</h2>
          <el-select v-model="partialIssuanceReason" placeholder="Выбрать причину" class="mb-3 w-full" size="large">
            <el-option v-for="item in reasonsList"
                       :key="item.id"
                       :label="item.reason"
                       :value="item.id"
            />
          </el-select>
        </div>
      </div>
      <div class="deliveryItemsSent-sum-product-payment-container">
        <h2>
          Способ оплаты
        </h2>
        <el-radio-group :model-value="paymentMethod" @change="onChangePaymentMethod">
          <el-radio label="not-selected">Не выбран</el-radio>
          <el-radio label="card">Банковская карта</el-radio>
          <el-radio label="cash">Наличные</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="deliveryItemsSent-item-container deliveryItemsSent-print">
      <el-button type="primary" @click="modalSmsSendVisible=true">Выдать</el-button>
      <el-button type="success" :icon="Printer"></el-button>
      <br/>
      <el-button type="primary" @click="onCheckIfIssued()">Проверить выдачу в Авито</el-button>
      <!--      <a class="barcode-button" href="#"><img src="img/barcode.png" alt="barcode-image"></a>-->
    </div>

    <el-dialog v-model="modalSmsSendVisible" title="Подтверждение" width="375px">
      <span>Выберите способ подтверждения</span>
      <el-select class="mt-1" v-model="shipmentCodeType" placeholder="Выбрать">
        <el-option
            v-for="item in shipmentCodeTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="modalSmsSendVisible=false">Отмена</el-button>
        <el-button type="primary" @click="onConfirm(shipmentCodeType)">
          Отправить
        </el-button>
      </span>
      </template>
    </el-dialog>

    <modal-sms-confirm
        v-model="modalSmsConfirmVisible"
        :shipment="shipment"
        :text="confirmText"
        :items="selectedItems"
        :reason="partialIssuanceReason"
        :shipmentCodeType="shipmentCodeType"
    />
  </div>
</template>

<style>
.deliveryItemsSent-sum-product-quantity-text-container .el-checkbox__label {
  display: none;
}
</style>

<script>
import {Printer} from '@element-plus/icons-vue';
import {mapActions, mapGetters, mapState} from "vuex";
import {ElNotification} from "element-plus";
import ModalSmsConfirm from "@/components/give/ModalSmsConfirm";
import {getDstClientFio} from "@/store/helper";

export default {
  name: "GiveConfirmAll",
  components: {ModalSmsConfirm},
  data() {
    return {
      Printer,
      paymentMethod: 'not-selected',
      modalSmsConfirmVisible: false,
      modalSmsSendVisible: false,
      confirmText: '',
      selectedItems: [],
      partialIssuanceReason: null,
      shipmentCodeType: 0,
    };
  },
  computed: {
    ...mapGetters({
      shipmentCodeTypes: 'apiV2ShipmentCodeTypeStore/all',
      defaultShipmentCode: 'apiV2ShipmentCodeTypeStore/default',
    }),
    ...mapState({
      shipment: state => state.shipments.readItem,
      reasonsList: state => state.refuse.reasonsList
    }),
    dstFio() {
      return getDstClientFio(this.shipment);
    },
  },
  created() {
    this.getShipment(this.$route.params.id);
    this.$store.dispatch('refuse/getReasons', 'REFUSAL_BY_RECEIVER');

    if (this.shipmentCodeTypes.length === 0) {
      this.fetchShipmentCodeTypes().then(() => {
        this.shipmentCodeType = this.defaultShipmentCode;
      });
    }
  },
  methods: {
    ...mapActions({
      getShipment: 'shipments/readItem',
      code: 'shipments/code',
      fetchShipmentCodeTypes: 'apiV2ShipmentCodeTypeStore/fetch',
    }),

    onChangePaymentMethod(value) {
      this.paymentMethod = value;
    },
    async onCheckIfIssued() {
      try {


        await this.$store.dispatch('apiV2ShipmentStore/checkIfIssued', this.shipment.uuid)
        ElNotification({
          title: 'Успешно',
          message: `Отправление №${this.shipment.number} выдано`,
          type: 'success',
        });

        this.$router.push({name: 'give-item', params: {id: this.shipment.id}});
      } catch ({message}) {
        ElNotification({
          title: 'Ошибка',
          message: message || 'Не удалось выдать отправление',
          type: 'error',
        });
      }
    },
    async onConfirm(shipmentCodeType) {
      console.log(shipmentCodeType)

      const {data} = await this.code({
        uuid: this.shipment.uuid,
        shipmentCodeType: shipmentCodeType,
      });
      this.confirmText = data.confirm_text;
      this.modalSmsConfirmVisible = true;
    },
  },
};
</script>
