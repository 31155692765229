import Statistic from "./Statistic.vue";

export const statisticRoutes = [
  {
    path: "/statistic",
    component: Statistic,
    name: "statistic",
    meta: { title: "Статистика" },
  },
];
