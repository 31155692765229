import apiV2Service from "@/api/V2/services/apiV2.service";

const store = {
    namespaced: true,
    actions: {
        generateUPD(context, franchiseeReportId) {
            return apiV2Service.postBlob(`documents/generateUPD/${franchiseeReportId}`)
        },
        generatePaymentOrder(context, franchiseeReportId) {
            return apiV2Service.postBlob(`documents/generatePaymentOrder/${franchiseeReportId}`)
        },
        downloadFranchiseeReport(context, franchiseeReportId) {
            return apiV2Service.postBlob(`documents/downloadFranchiseeReport/${franchiseeReportId}`)
        },
        downloadPpAct(context, uuid) {
            return apiV2Service.postBlob(`documents/downloadPpAct/${uuid}`)
        },
    },
};
export default store;
