<template>
  <li class="sideBar-item">
    <!-- ПВЗ -->
    <router-link
      :to="`${group.link}/${defaultLink(group.link)}`"
      class="sideBar-button"
      exact-active-class="sideBar-button-active"
      :class="`${group.icon}-link`"
      v-if="group.links"
    >
      {{ group.title }}
    </router-link>

    <!-- внешняя ссылка -->
    <a v-else-if="group.target" :target="group.target" class="sideBar-button sidebar-link" :class="`${group.icon}-link`" :href="group.link">{{ group.title }}</a>
    
    <router-link
      :to="group.link"
      class="sideBar-button sidebar-link"
      v-else
      :class="`${group.icon}-link`"
      exact-active-class="sideBar-button-active"
      :target="group.target"
    >
      {{ group.title }}
    </router-link>

    <a
      id="dropdownAdresLink"
      v-if="group.links"
      type="button"
      class="btn dropdown-toggle customArrow"
      :class="{ show: opened }"
      href="#"
      data-bs-toggle="dropdown"
      @click.prevent="toogle"
    >
      <span class="visually-hidden"></span>
    </a>

    <ul
      class="dropdown-menu dropdown-transition"
      :class="{ show: opened }"
      aria-labelledby="dropdownAdresLink"
    >
      <li
        v-for="link in group.links"
        v-bind:key="link.id"
        class="dropdown-item"
      >
        <router-link :to="{ path: link.link }">{{ link.title }}</router-link>
      </li>
    </ul>
  </li>
</template>
<style scoped>
.dropdown-item > a{
  overflow: hidden;
  text-overflow: ellipsis;
}
.router-link-active {
  color: #fff;
}
</style>
<script>
export default {
  name: "NavigationGroup",
  props: {
    group: {
      title: String,
      id: Number,
      icon: String,
      links: Array,
      link: String,
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    toogle() {
      this.opened = !this.opened;
    },
    defaultLink(link) {
      if (link == '/address') {
        return this.user.points.length ? this.user.points[0].id : (this.user.point?.id || '');
      }
      
      return '';
    }
  },
};
</script>
