import Users from "./Users.vue";
import RequestLegalEntityView from "@/views/registration/RequestLegalEntityView";

export const usersRoutes = [
  {
    path: "/users",
    component: Users,
    name: "users",
  },
  {
    path: "/users/requestsRegistration",
    component: RequestLegalEntityView,
    name: 'requestsRegistration'
  }
];
