import Give from "./Give.vue";
import GiveDetail from "./GiveDetail.vue";
import GiveConfirmAll from "./GiveConfirmAll.vue";

export const giveRoutes = [
  {
    path: "/give",
    component: Give,
    name: "give",
    meta: { title: "Выдача отправлений" },
  },
  {
    path: "/give/:id",
    component: GiveDetail,
    name: "give-item",
    meta: { title: "Выдача отправления", isHeaderBackLink: true },
  },
  {
    path: "/give/:id/confirm-all",
    component: GiveConfirmAll,
    name: "give-confirm-all",
    meta: { title: "Подтверждение выдачи отправления", isHeaderBackLink: true },
  },
];
