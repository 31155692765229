import Inventory from "./Inventory.vue";

export const inventoryRoutes = [
  {
    path: "/inventory",
    component: Inventory,
    name: "inventory",
    meta: { title: "Инвентаризация" },
  },
];
