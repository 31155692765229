<template>
  <div v-if="loadingItem" class="pt-4">Загрузка..</div>
  <div v-else-if="Object.keys(readItemErrors).length" class="pt-4">
    {{ readItemErrors.error }}
  </div>
  <div v-else>
    <div v-if="isShowAlert" class="confirm-age">
      <h2>Подтвердите, что получатель старше 18 лет</h2>
      <p>Проверьте документы получателя! Согласно законодательству РФ запрещается продажа лицамм
        в возрасте до 18 лет алкогольных
        и слабоалкогольных напитков, табачных изделий, продукции, содержащей элементы насилия и жестокости</p>
      <button class="red-button" @click="isConfirmAge = true">Подтвердить</button>
    </div>

    <EditDetail
      :shipment="shipment"
      :mode="mode"
      :loading="loadingItem"
      @onEdit="onEdit"
      @onSave="onSave"
      @onDelete="onDelete"
      @onInput="onInput"
      :canEdit="canEdit"
    />

    <ModalDelete
      :isModalShow="isDeleteModalShow"
      :isBtnsDisabled="loadingItem"
      title="Вы уверены, что хотите удалить отправление?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Действие необратимо"
      @close="toggleDeleteModal(false)"
      @onCancel="toggleDeleteModal(false)"
      @onConfirm="onConfirmDelete"
    />
    <ModalRefuse v-if="isModalRefuseShow" @close="modalRefuseToggle" from="give" :has-parent="shipment.parent_id"/>
    <div class="give-detail__buttons" v-else-if="shipment.status != 90">
      <template v-if="canPrint">
        <el-button type="success" @click="() => printSticker(shipment.id)">Этикетка</el-button>
        <el-button type="success" @click="() => receiptAvitoAcceptance(shipment.id)">Квитанция прием</el-button>
        <el-button type="success" @click="() => receiptAvitoIssuance(shipment.id)">Квитанция выдача</el-button>
      </template>
      <template v-if="shipment.status == 80 && user.role_id != 9"><!-- скрываем для мультифранча (9)-->
        <!--<el-button>Примерка</el-button>-->
        <el-button
          :disabled="isShowAlert"
          @click="$router.push({ name: 'give-confirm-all' })"
        >Выдать</el-button>
        <!--<el-button>Выдать частично</el-button>-->
        <el-button @click="modalRefuseToggle()">Отказ</el-button>
      </template>
      <el-button v-if="shipment.has_fitting && !isFittingStarted && shipment.status == 80" @click="runFitting()">Начать примерку</el-button>
      <router-link v-if="shipment.status == 0" class="el-button el-button--default text-decoration-none" :to="{path: `/accept/${shipment.id}`}">Принять</router-link>
    </div>
    <div class="my-4 confirm-age" v-if="isFittingStarted">
      <p>Идет примерка. осталось времени: {{ date.toLocaleTimeString() }}</p>
      <el-button @click="$router.push({ name: 'give-confirm-all' })">Успешно завершить</el-button>
      <el-button @click="modalRefuseToggle()">Товар не подошел</el-button>
    </div>

    <div class="my-4 confirm-age" v-if="shipment.refuse_reason?.reason">
      <p>{{shipment.refuse_reason?.reason}}</p>
      <button class="red-button" type="button" @click="() => commercialAct(shipment.id)">Коммерческий акт</button>
    </div>
    <ShipmentGoods :shipment="shipment" />
    <el-tabs v-model="activeName" class="shipments-detail-tabs" @tab-click="tabClick">
      <el-tab-pane label="История" name="shipment-history">
        <ShipmentHistory :shipment="shipment"/>
      </el-tab-pane>
      <el-tab-pane label="Логи" name="shipment-logs" >
        <ShipmentLogTable :logs="logs" v-loading="loadingLogs" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import EditDetail from "src/components/shipments/EditDetail.vue";
import ModalDelete from "src/components/modal/ModalDelete.vue";
import ModalRefuse from "src/components/modal/ModalRefuse.vue";
import ShipmentGoods from "src/components/shipments/ShipmentGoods.vue";
import ShipmentHistory from "src/components/shipments/ShipmentHistory.vue";
import ShipmentLogTable from "@/components/shipments/ShipmentLogTable.vue";
import {ElNotification} from 'element-plus';
import { usePrint } from '@/composition/usePrint.js';
import { useFitting } from '@/composition/useFitting.js';
import {ref} from "vue";
import {mapActions} from "vuex";

export default {
  name: "GiveDetail",
  components: {
    ShipmentLogTable,
    EditDetail,
    ModalDelete,
    ModalRefuse,
    ShipmentGoods,
    ShipmentHistory,
  },
  setup() {
    return {
      ...usePrint(),
      ...useFitting(),
    }
  },
  data() {
    return {
      loadingItem: true,
      loadingLogs: false,
      mode: "read", //'edit'
      isDeleteModalShow: false,
      isConfirmAge: false,
      isModalRefuseShow: false,
      activeName: ref('shipment-history'),
      logs: [],
    };
  },
  created() {
    this.getItem().finally(() => {
      const title =  this.isShipmentDetailRoute ? "Выдача отправления №" : 'Отправление ';
      this.$store.commit("SET_TITLE", title + this.shipment.number);
      this.loadingItem = false;
    });
    this.logs = [];
  },
  computed: {
    canPrint() {
      return +this.shipment.status && +this.shipment.status != 100;
    },
    canEdit() {
      return this.$store.getters["user/role_id"] == 1;
    },
    isShipmentDetailRoute() {
      return this.$route.name == 'shipmentsDetail';
    },
    shipment() {
      return this.$store.state.shipments.readItem;
    },
    readItemErrors() {
      return this.$store.state.shipments.errors.readItem;
    },
    isShowAlert() {
      return this.shipment.for_adults_only && !this.isConfirmAge;
    },
    user() {
      return this.$store.state.user.user;
    }
  },
  methods: {
    ...mapActions({
      readItem: 'shipments/readItem',
      fetchLogs: 'apiV2ShipmentLogStore/fetch',
    }),

    runFitting() {
      const callback = () => {
        ElNotification({
          title: 'Уведомление',
          message: 'Время на примерку заказа истекло',
          type: 'info',
          duration: 0
        });
      };

      this.startFitting(0, 20, callback);
      this.isFittingStarted = true;
    },

    async getItem() {
      this.loadingItem = true;
      await this.readItem(this.$route.params.id).finally(() => {
        this.loadingItem = false;
      });
    },
    onEdit() {
      this.mode = "edit";
    },
    async onSave() {
      this.loadingItem = true;

      await this.$store.dispatch("shipments/updateItem");
      this.loadingItem = false;
      this.mode = "read";
      await this.getItem();
    },
    onDelete() {
      this.isDeleteModalShow = true;
    },
    onInput(valObj) {
      let data = { ...this.shipment, ...valObj };
      this.$store.commit("shipments/SET_READ_ITEM", data);
    },
    toggleDeleteModal(val) {
      this.isDeleteModalShow = val;
    },
    modalRefuseToggle() {
      this.isModalRefuseShow = !this.isModalRefuseShow;
      this.stopFitting();
    },
    async onConfirmDelete() {
      this.loadingItem = true;
      await this.$store.dispatch("shipments/delete", this.shipment.id);
      this.loadingItem = false;
      this.$router.push({ name: "shipments" });
    },
    tabClick(tab) {
      if (tab.props.name !== 'shipment-logs' || this.logs.length > 0) {
         return;
      }

      this.loadingLogs = true;
      this.fetchLogs(this.$route.params.id).then((result) => {
        this.logs = result;
      }).finally(() => {
        this.loadingLogs = false;
      });
    },
  },
};
</script>

<style lang="scss">
.give-detail {
  &__buttons {
    margin-bottom: 26px;
  }
}

.el-table .cell {
  word-break: normal;
}
</style>
