<template>
  <div class="context_menu">
      <div class="breadcrumb-profilePVZ-container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a :href="`/address/${$route.params.id}`">Пункт выдачи</a></li>
              <li class="breadcrumb-item active"><a :href="`/address/${$route.params.id}/team`">Команда </a></li>
              <!-- <li class="breadcrumb-item"><a href="#">Заказ расходников</a></li> -->
            </ol>
          </nav>
      </div>
      
      <div class="sort-form">
          <form class="sort-items">
              <span class="custom-search">
                  <button class="invise-searchButton"></button>
                  <input required class="custom-search-input form-control" placeholder="Поиск" type="search">
                  <button onclick="clearSearch()" type="button" class="close-icon"></button>
              </span>
              <select class="team-sort-select form-select" aria-label="">
                  <option selected>Все типы</option>
                  <option value="1">Команда</option>
                  <option value="2">Расходники</option>
                  <option value="3">Отчеты</option>
              </select>
              <select class="team-sort-select form-select" aria-label="">
                  <option selected>Все статусы</option>
                  <option value="1">Работает</option>
                  <option value="2">Уволен</option>
              </select>
          </form>

          <button class="addWorker" data-bs-toggle="modal" data-bs-target="#addNewWorker">
              Добавить сотрудника
          </button>
          <!-- modal window -->
          <div class="modal fade" id="addNewWorker" aria-labelledby="newWorkerLabel"
              aria-hidden="true" data-bs-backdrop="static">
              <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
                  <div class="modal-content  customModalNewWorker">
                      <button type="button" class="btn-close custom-close-modal" 
                      data-bs-dismiss="modal" aria-label="Close"></button>
                      <div class="modal-body">
                          <form class="modal-add-worker addWorkerValidation" onsubmit="addWorkerEmptyValidation()" novalidate>
                              <h2 class="form-name">Добавление сотрудника</h2>
                              <div class="form-group modal-add-worker-form-name">
                                  <label for="modalAddWorkerFormName">ФИО Сотрудника</label>
                                  <div>
                                      <input type="text" class="form-control modal-add-worker-form-style-input" id="modalAddWorkerFormSurname"
                                          pattern="[а-яА-ЯёЁ]{1,20}[-][а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}" aria-describedby="nameAdd" placeholder="Фамилия">
                                      <div class="invalid-feedback">
                                          <p>Неверный формат</p>
                                          
                                      </div>
                                  </div>
                                  <div>
                                      <input type="text" class="form-control emptyCheck nameVal modal-add-worker-form-style-input"
                                          id="modalAddWorkerFormName"
                                          pattern="[а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}"
                                          aria-describedby="nameAdd" placeholder="Имя*" required>
                                      <div class="invalid-feedback">
                                          <p class="invalid-feedback-text">Неверный формат</p>
                                      </div>
                                  </div>
                                  <div>
                                      <input type="text" class="form-control modal-add-worker-form-style-input" id="modalAddWorkerForSecondName"
                                          pattern="[а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}" aria-describedby="nameAdd"
                                          placeholder="Отчество">
                                      <div class="invalid-feedback">
                                          <p>Неверный формат</p>
                                          
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col">
                                      <div class="form-group modal-add-worker-form-birth">
                                          <label for="modalAddWorkerFormBirth">Дата рождения</label>
                                          <div>
                                              <input type="date" class="form-control dateVal modal-add-worker-form-style-input addDate" id="modalAddWorkerFormBirth" name="addDate"
                                                  aria-describedby="birthAdd" min="1921-01-01" max="2007-01-01" required>
                                              <div class="invalid-feedback">
                                                  <p class="text-date-val">Сотрудник не может быть моложе 14 и старше 100 лет</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col">
                                      <div class="form-group modal-add-worker-form-number">
                                          <label for="modalAddWorkerFormNumber">Номер телефона</label>
                                          <div>
                                              <input type="tel" class="form-control telVal modal-add-worker-form-style-input" id="modalAddWorkerFormNumber"
                                                  aria-describedby="numberAdd"
                                                  pattern="[\+][7][\-][0-9]{3}[\-][0-9]{3}[\-][0-9]{2}[\-][0-9]{2}" 
                                                  placeholder="Введите номер"
                                                  required>
                                              <div class="invalid-feedback">
                                                  <p>
                                                      Нужно добавить номер телефона, формат +71111111111
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group modal-add-worker-form-passport">
                                  <label for="modalAddWorkerFormPassport">Серия и номер паспорта</label>
                                  <div>
                                      <input type="text" class="form-control emptyCheck passportVal modal-add-worker-form-style-input" id="modalAddWorkerFormPassport"
                                          aria-describedby="passportAdd" maxlength="12"
                                          pattern="[0-9]{4} [0-9]{6}"
                                          placeholder="Введите номер" required>
                                      <div class="invalid-feedback">
                                          <p class="invalid-feedback-text">Нужно добавить серию и номер паспорта, формат 1111 111111</p>
                                      </div>
                                  </div>
                                  
                              </div>
                              <div class="form-group modal-add-worker-form-mail">
                                  <label for="modalAddWorkerFormMail">E-mail</label>
                                  <div>
                                      <input type="text" class="form-control emptyCheck modal-add-worker-form-style-input" id="modalAddWorkerFormMail"
                                          aria-describedby="mailAdd" placeholder="Введите e-mail"
                                          pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$" required>
                                      <div class="invalid-feedback">
                                          <p class="invalid-feedback-text">Неверный формат</p>
                                      </div>
                                  </div>
                                  
                              </div>
                              <div class="form-group modal-add-worker-form-place">
                                  <label for="modalAddWorkerFormPlace">Пункт выдачи</label>
                                  <div>
                                      <select id="modalAddWorkerFormPlace" class="form-select modal-add-worker-form-style-select"
                                          aria-label="modalAddWorkerFormPlace" required>
                                          <option disabled selected value="">Выбрать пункт выдачи</option>
                                          <option value="1">ПВЗ1</option>
                                          <option value="2">ПВЗ2</option>
                                          <option value="3">ПВЗ3</option>
                                      </select>
                                      <div class="invalid-feedback">
                                          <p>Нужно выбрать пункт выдачи</p>
                                      </div>
                                      <!-- <input type="text" class="form-control modal-add-worker-form-style-input" id="modalAddWorkerFormPlace"
                                          aria-describedby="placeAdd" placeholder="Выбрать пункт выдачи" required>
                                        -->
                                  </div>
                                  
                              </div>
                              <button class="addWorker addWorker-modal-button" type="submit">Добавить</button>
                              
                          </form>
                      </div>
                  </div>
              </div>
          </div>
          
          <!-- modal where copy data -->
          <div class="modal fade" id="copyNewWorker" aria-labelledby="copyWorkerLabel" aria-hidden="true">
              <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
                  <div class="modal-content customModalCopyWorker">
                      <div class="modal-body">
                          <div id="copyInfo">
                              <h2 class="form-name">Карта сотрудника</h2>
                              <p class="form-context">Сотрудник Иван Иванов добавлен</p>
                              <p class="form-context">Логин: <span class="copyDataLogin">ABC123</span></p>
                              <p class="form-context">Пароль: <span class="copyDataPassword">ABC123</span></p>
                          </div>
                          <div class="rightCopy">
                              <button class="copyWorker" data-bs-dismiss="modal" onclick="copyText()">Скопировать</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- modal window edit - view -->
      <div class="modal fade" id="editWorkerModal">
          <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
              <div class="modal-content customModalEditWorker">
                  <div class="modal-header custom-modal-header">
                      <h2 class="custom-modal-header-heading">Карта сотрудника</h2>
                      <button type="button" class="btn-close custom-close-modal-edit" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body ">
                      <form class="modal-edit-worker editWorkerValidation" onsubmit="editWorkerEmptyValidation()" novalidate>
                          <div class="form-group modal-edit-worker-form-name">
                              <label for="modalEditWorkerFormName">ФИО Сотрудника</label>
                              <div class="edit-name-margin">
                                  <input type="text" class="form-control modal-edit-worker-form-style-input" id="modalEditWorkerFormSurname"
                                      pattern="[а-яА-ЯёЁ]{1,20}[-][а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}" aria-describedby="surnameEdit" placeholder="Фамилия">
                                  <div class="invalid-feedback">
                                      <p>Неверный формат</p>
                                  </div>
                              </div>
                              <div class="edit-name-margin">
                                  <input type="text" class="form-control emptyCheck modal-edit-worker-form-style-input" id="modalEditWorkerFormName"
                                      pattern="[а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}" aria-describedby="nameEdit" placeholder="Имя*" required>
                                  <div class="invalid-feedback">
                                      <p class="invalid-feedback-text">Неверный формат</p>
                                  </div>
                              </div>
                              <div class="edit-name-margin">
                                  <input type="text" class="form-control modal-edit-worker-form-style-input" id="modalEditWorkerFormSecondName"
                                      pattern="[а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}" aria-describedby="secondNameEdit" placeholder="Отчество">
                                  <div class="invalid-feedback">
                                      <p>Неверный формат</p>   
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-birth">
                                      <label for="modalEditWorkerFormBirth">Дата рождения</label>
                                      <div>
                                          <input type="date" class="form-control dateVal  modal-edit-worker-form-style-input editDate"
                                              id="modalEditWorkerFormBirth" name="editDate" aria-describedby="birthAdd" min="1921-01-01" max="2007-01-01"
                                                required>
                                          <div class="invalid-feedback">
                                              <p class="text-date-val">Сотрудник не может быть моложе 14 и старше 100 лет</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-number">
                                      <label for="modalEditWorkerFormNumber">Номер телефона</label>
                                      <div>
                                          <input type="tel" class="form-control telValEdit modal-edit-worker-form-style-input"
                                              id="modalEditWorkerFormNumber" aria-describedby="numberEdit"
                                              pattern="[\+][7][\-][0-9]{3}[\-][0-9]{3}[\-][0-9]{2}[\-][0-9]{2}" placeholder="Введите номер"
                                              required>
                                          <div class="invalid-feedback">
                                              <p>Нужно добавить номер телефона, формат +71111111111</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-passport">
                                      <label for="modalAddWorkerFormPassport">Серия и номер паспорта</label>
                                      <div>
                                          <input type="text" class="form-control emptyCheck passportEditVal modal-edit-worker-form-style-input"
                                              id="modalEditWorkerFormPassport" aria-describedby="passportEdit" maxlength="12" pattern="[0-9]{4} [0-9]{6}"
                                              placeholder="Введите номер" required>
                                          <div class="invalid-feedback">
                                              <p class="invalid-feedback-text">Нужно добавить серию и номер паспорта, формат 1111 111111</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-mail">
                                      <label for="modalEditWorkerFormMail">E-mail</label>
                                      <div>
                                          <input type="email" class="form-control emptyCheck modal-edit-worker-form-style-input" id="modalEditWorkerFormMail"
                                              aria-describedby="mailEdit"
                                              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$"
                                              placeholder="Введите E-mail" required>
                                          <div class="invalid-feedback">
                                              <p class="invalid-feedback-text">Нужно добавить почтовый адрес</p>
                                          </div>
                                      </div>
                                      
                                  </div>
                              </div>
                          </div>
                          <div class="modal-edit-worker-navigation">
                              <button class="modal-edit-worker-navigation-button on" type="button" disabled>Информация</button>
                              <button class="modal-edit-worker-navigation-button" type="button" data-bs-target="#editWorkerModalPVZ" data-bs-toggle="modal" data-bs-dismiss="modal">ПВЗ</button>
                          </div>
                          <div class="edit-worker-info-block">
                              <h2>Права доступа</h2>
                              <div class="align-checkbox">
                                  <span>Команда</span>
                                  <div class="form-check form-switch">
                                      <input class="form-check-input" type="checkbox" role="switch" id="infoTeam" checked>
                                  </div>
                              </div>
                              <div class="align-checkbox">
                                  <span>Расходники</span>
                                  <div class="form-check form-switch">
                                      <input class="form-check-input" type="checkbox" role="switch" id="infoCosts">
                                  </div>
                              </div>
                              <div class="align-checkbox">
                                  <span>Отчеты</span>
                                  <div class="form-check form-switch">
                                      <input class="form-check-input" type="checkbox" role="switch" id="infoReport">
                                  </div>
                              </div>
                          </div>
                          <div class="form-group modal-edit-worker-form-password">
                              <div class="modal-edit-worker-form-password-position">
                                  <div>
                                      <label for="modalEditWorkerFormPassword">Пароль</label>
                                      <input type="text" class="form-control modal-edit-worker-form-style-input"
                                          id="modalEditWorkerFormPassword" aria-describedby="passwordEdit" placeholder="X123lss">
                                  </div>
                                  <button type="button"
                                          class="edit-worker-modal-button"
                                          onclick="generatePassword()">Сгенерировать</button>
                                  <button type="button" class="edit-worker-modal-button">Установить</button>
                              </div>
                              <button type="button" data-bs-toggle="modal" data-bs-target="#deleteWorker" class="edit-worker-modal-button-delete">Удалить аккаунт</button>
                          </div>
                          <button class="edit-worker-modal-button-save" type="submit">Сохранить изменения</button>
                      </form>
                  </div>
                  
              </div>
          </div>
      </div>
      <!-- modal delete data -->
      <div class="modal fade" id="deleteWorker">
          <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
              <div class="modal-content customModalDeleteWorker">
                  <div class="modal-header custom-modal-header">
                      <h2 class="custom-modal-header-heading">Карта сотрудника</h2>
                  </div>
                  <div class="modal-body">
                      <form class="modal-delete-worker deleteWorkerValidation" novalidate>
                          <div class="form-group modal-delete-worker-form-name">
                              <label for="modalDeleteWorkerFormName">Причина удаления аккаунта</label>
                              <div>
                                  <textarea class="form-control modal-delete-worker-form-style-input"
                                          placeholder="Причина удаления"
                                          maxlength="500"
                                          required
                                          aria-describedby="nameDelete"
                                          id="modalDeleteWorkerFormName"></textarea>
                                  <div class="invalid-feedback">
                                      <p>Укажите причину удаления</p>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <button type="button" data-bs-toggle="modal" data-bs-target="#editWorkerModal" class="delete-worker-modal-button-save cancel">Отменить</button>
                              </div>
                              <div class="col">
                                  <button type="submit" class="delete-worker-modal-button-save">Удалить аккаунт</button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>

      <!-- modal window edit - PVZ -->
      <div class="modal fade" id="editWorkerModalPVZ">
          <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
              <!-- TO do delete after will be one modal window -->
              <div class="modal-content customModalEditWorker2">
                  <div class="modal-header custom-modal-header">
                      <h2 class="custom-modal-header-heading">Карта сотрудника</h2>
                      <button type="button" class="btn-close custom-close-modal-edit" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                  </div>
                  <div class="modal-body ">
                      <form class="modal-edit-worker2 editWorkerValidation2" onclick="editWorkerEmptyValidationn()" novalidate>
                          <div class="form-group modal-edit-worker-form-name">
                              <label for="modalEditWorkerFormName">ФИО Сотрудника</label>
                              <div>
                                  <div>
                                      <input type="text" class="form-control modal-add-worker-form-style-input" id="modalEditWorkerFormSurname"
                                          pattern="[а-яА-ЯёЁ]{1,20}[-][а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}" aria-describedby="nameAdd" placeholder="Фамилия">
                                      <div class="invalid-feedback">
                                          <p>Неверный формат</p>
                                      </div>
                                  </div>
                                  <div>
                                      <input type="text" class="form-control emptyCheck nameVal modal-add-worker-form-style-input" id="modalEditWorkerFormName"
                                          pattern="[а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}" aria-describedby="nameAdd" placeholder="Имя*" required>
                                      <div class="invalid-feedback">
                                          <p class="invalid-feedback-text">1</p>
                                      </div>
                                  </div>
                                  <div>
                                      <input type="text" class="form-control modal-add-worker-form-style-input" id="modalEditWorkerFormSecondName"
                                          pattern="[а-яА-ЯёЁ]{1,20}|[а-яА-ЯёЁ]{1,20}[\s][а-яА-ЯёЁ]{1,20}" aria-describedby="nameAdd" placeholder="Отчество">
                                      <div class="invalid-feedback">
                                          <p>Неверный формат</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-birth">
                                      <label for="modalEditWorkerFormBirth">Дата рождения</label>
                                      <div>
                                          <input type="date" class="form-control dateVal modal-edit-worker-form-style-input editDate2"
                                              id="modalEditWorkerFormBirth" name="editDate" aria-describedby="birthAdd" value=""  min="1921-01-01" max="2007-01-01" required>
                                          <div class="invalid-feedback">
                                              <p class="text-date-val">Сотрудник не может быть моложе 14 и старше 100 лет</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-number">
                                      <label for="modalEditWorkerFormNumber">Номер телефона</label>
                                      <div>
                                          <!-- to Do change when will be one modal window -->
                                          <input type="tel" class="form-control telValEdit2 modal-edit-worker-form-style-input"
                                              id="modalEditWorkerFormNumber" aria-describedby="numberEdit"
                                              pattern="[\+][7][\-][0-9]{3}[\-][0-9]{3}[\-][0-9]{2}[\-][0-9]{2}" placeholder="Введите номер"
                                              required>
                                          <div class="invalid-feedback">
                                              <p>Нужно добавить номер телефона, формат +71111111111</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-passport">
                                      <label for="modalAddWorkerFormPassport">Серия и номер паспорта</label>
                                      <div>
                                          <input type="text" class="form-control emptyCheck passportEditVal2 modal-edit-worker-form-style-input"
                                              id="modalEditWorkerFormPassport" aria-describedby="passportEdit" maxlength="12" pattern="[0-9]{4} [0-9]{6}"
                                              placeholder="Введите номер" required>
                                          <div class="invalid-feedback">
                                              <p class="invalid-feedback-text">1</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group modal-edit-worker-form-mail">
                                      <label for="modalEditWorkerFormMail">E-mail</label>
                                      <div>
                                          <input type="email" class="form-control emptyCheck modal-edit-worker-form-style-input" id="modalEditWorkerFormMail"
                                              aria-describedby="mailEdit" placeholder="Введите E-mail"
                                                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$" required>
                                          <div class="invalid-feedback">
                                              <p class="invalid-feedback-text">1</p>
                                          </div>
                                      </div>
                          
                                  </div>
                              </div>
                          </div>
                          <div class="modal-edit-worker-navigation">
                              <button class="modal-edit-worker-navigation-button" type="button" data-bs-toggle="modal" data-bs-target="#editWorkerModal">Информация</button>
                              <button class="modal-edit-worker-navigation-button on" type="button" disabled>ПВЗ</button>
                          </div>
                          <div class="modal-pvz-info delete-1">
                              <h2 class="modal-pvz-info-city">Краснодар_13</h2>
                              <p class="modal-pvz-info-address">Ул. Ленина, д. 40, г. Майкоп, Республика Адыгея, 385000</p>
                              <button onclick="addressDelete(1)" type="button" class="btn-close modal-pvz-info-close-button" aria-label="Close"></button>
                          </div>
                          <div class="modal-pvz-info delete-2">
                              <h2 class="modal-pvz-info-city">Краснодар_13</h2>
                              <p class="modal-pvz-info-address">Ул. Ленина, д. 40, г. Майкоп, Республика Адыгея, 385000</p>
                              <button onclick="addressDelete(2)" type="button" class="btn-close modal-pvz-info-close-button" aria-label="Close"></button>
                          </div>
                          <div class="form-group modal-edit-worker-form-PVZ">
                              <label for="modalEditPVZWorkerFormRole">Прикрепить к новому ПВЗ</label>
                              <select id="modalEditPVZWorkerFormRole" class="form-select modal-edit-worker-form-style-select"
                                  aria-label="modalEditPVZWorkerFormRole" required>
                                  <option disabled value="" selected>ПВЗ</option>
                                  <option value="1">ПВЗ1</option>
                                  <option value="2">ПВЗ2</option>
                                  <option value="3">ПВЗ3</option>
                              </select>
                              <div class="invalid-feedback">
                                  <p>Нужно выбрать ПВЗ</p>
                                  
                              </div>
                          </div>
                          
                          <button type="submit" class="edit-worker-modal-button-save">Сохранить изменения</button>
                      </form>
                  </div>
              </div>
          </div>
      </div>

      <div class="team-table">
          <ul class="table-container table-head">
              <li class="table-items-head  table-items-name">
                  <span>ФИО</span>
              </li>
              <li class="table-items-head  table-items-secure">
                  <span>Права доступа</span>
              </li>
              <li class="table-items-head  table-items-mail">
                  <span>Email</span>
              </li>
              <li class="table-items-head  table-items-phone">
                  <span>Телефон</span>
              </li>
              <li class="table-items-head  table-items-status">
                  <span>Статус</span>
              </li>
              <li class="table-items-head  table-items-burn">
                  <span>Дата рождения</span>
              </li>
              <li class="table-items-head  table-items-register">
                  <span>Дата регистрации</span>
              </li>
              <li class="table-items-head  table-items-buttons">
                  <span>Действия</span>
              </li>
          </ul>
          <ul class="table-container" v-for="user in users" :key="user.id">
              <li class="table-items table-items-name">
                  <span>{{user.last_name}} {{user.first_name}} {{user.middle_name}}</span>
              </li>
              <li class="table-items table-items-secure">
                  <span>{{user.role_id}}</span>
              </li>
              <li class="table-items table-items-mail">
                  <span>{{user.email_adress}}</span>
              </li>
              <li class="table-items table-items-phone">
                  <span>+78945689765</span>
              </li>
              <li class="table-items table-items-status">
                  <span>{{user.status}}</span>
              </li>
              <li class="table-items table-items-burn">
                  <span>{{user.birthday ? new Date(user.birthday).toLocaleDateString() : ''}}</span>
              </li>
              <li class="table-items table-items-register">
                  <span>{{user.registration_date ? new Date(user.registration_date).toLocaleDateString() : ''}}</span>
              </li>
              <li class="table-items table-items-buttons">
                  <a class="team-lock" href="#"><img src="@/assets/img/lock_icon.svg" alt="lock"></a>
                  <a class="team-edit" role="button" data-bs-toggle="modal" href="#editWorkerModal"><img src="@/assets/img/pen_icon.svg" alt="pen"></a>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
export default {
  name: "AddressTeam",
  components: {},
  computed: {
      users() {
          return this.$store.state.user.list;
      }
  },
  created() {
    this.$store.dispatch('user/getUsers', { point_id: this.$route.params.id });
  },
  mounted() {
    this.$store.commit("SET_TITLE", "EXMAIL");
  },
};
</script>
