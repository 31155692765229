<template>
  <nav class="navbar">
    <div class="header-title">
      <a
        v-if="isHeaderBackLink"
        class="back-button"
        href="javascript:void(0)"
        @click.prevent="$router.go(-1)"
      >
        <img src="@/assets/img/back_icon.svg" alt="backIcon" />
      </a>
      <a
        class="navbar-brand"
        href="javascript:void(0)"
        @click.prevent="onTitleClick"
        >{{ title }}</a
      >
    </div>

    <div class="right-part-nav">
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Обновить справочники"
        placement="bottom-start"
      >
        <el-button
          class="dict-reset-button"
          @click="resetDictionary"
          :icon="Refresh"
        ></el-button>
      </el-tooltip>
      <Lang :current="languages.current" :list="languages.list" />
      <NotifyBtn :count="notifications.length" />
      <NotifyDropdown :notifications="notifications" />
      <Profile :avatar="profile.avatar" />
    </div>
  </nav>
</template>

<script>
import Lang from "./Lang.vue";
import NotifyBtn from "./NotifyBtn.vue";
import NotifyDropdown from "./NotifyDropdown.vue";
import Profile from "./Profile.vue";
import {ElNotification} from "element-plus";
import {Refresh} from '@element-plus/icons-vue'

export default {
  name: "Header",
  data() {
    return {
      notifications: [],
      languages: {
        current: "Ru",
        list: ["Ru"],
      },
      profile: {
        avatar: undefined,
      },
      Refresh,
    };
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
    isHeaderBackLink() {
      return this.$route.meta.isHeaderBackLink;
    },
  },
  methods: {
    resetDictionary() {
      const pointsPromise = this.$store.dispatch('points/getList', {force: true});
      const ceilsPromise = this.$store.dispatch('ceils/getList', {force: true});

      Promise.all([pointsPromise, ceilsPromise]).then(() => {
        return ElNotification({
          title: 'Справочники успешно обновлены',
          message: 'Справочники загружены с сервера',
          type: 'success',
        });
      }, (error) => {
        return ElNotification({
          title: 'Ошибка при сбросе справочников',
          message: error,
          type: 'error',
        });
      });
    },
  },
  components: {
    Lang,
    Profile,
    NotifyBtn,
    NotifyDropdown,
  },
};
</script>
<style>
.dict-reset-button.el-button--default:hover {
    background: none;
    color: #556EE6;
}
</style>
