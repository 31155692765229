import {
  getAll,
  createItem,
  readItem,
  updateItem,
  deleteItem,
} from "src/api/points";

import parseStatus from "./parseStatus";
import {isDataUpdateRequired} from "@/store/helper";
import indexDB, {STORAGE_POINTS} from '@/helpers/indexdb'

// Период обновления в минутах
const TIME_TO_REFRESH = 10;
const store = {
  namespaced: true,
  state() {
    return {
      list: [],
      listLastUpdate: null,
      editItem: undefined,
      errors: {},
    };
  },
  getters: {
    pointSelect(state) {
      return state.list.map((item) => {
        return {value: item.id, title: item.name};
      });
    },
  },
  actions: {
    async getList(context, params = {}) {
      if (context.state.listLastUpdate && context.state.list.length > 0 && !isDataUpdateRequired(context.state.listLastUpdate)) {
        return;
      }

      const interval = await indexDB.lastUpdateInterval(STORAGE_POINTS);

      if (interval < TIME_TO_REFRESH && !params.force) {
        indexDB.getItems(STORAGE_POINTS).then((result) => {
          context.commit("SET_LIST", result);
        });

        return;
      }

      try {
        const filters = params.filters ?? {};
        const {status, data} = await getAll(filters);
        if (status === 200) {
          context.commit("SET_LIST", data);
          indexDB.saveItems(STORAGE_POINTS, data);
        }
      } catch ({message, response = {}}) {
        context.commit("SET_ERROR", parseStatus(response.status) || message);
      }

      context.commit("SET_LIST_LAST_UPDATE", new Date().getTime());
    },
    async createItem(context) {
      try {
        const {status, data} = await createItem(context.state.editItem);
        if (status === 200) {
          context.commit("SET_ITEM", data);
        }
      } catch ({message, response = {}}) {
        context.commit(
          "SET_CREATE_ERROR",
          parseStatus(response.status) || message
        );
      }
    },
    async readItem(context, id) {
      try {
        const {status, data} = await readItem(id);
        if (status === 200) {
          context.commit("SET_ITEM", data);
        }
      } catch ({message, response = {}}) {
        context.commit("SET_ERROR", parseStatus(response.status) || message);
      }
    },
    async updateItem(context, id) {
      try {
        const {status, data} = await updateItem(id, context.state.editItem);
        if (status === 200) {
          context.commit("SET_ITEM", data);
        }
      } catch ({message, response = {}}) {
        context.commit(
          "SET_CREATE_ERROR",
          parseStatus(response.status) || message
        );
      }
    },
    async deleteItem(context, id) {
      try {
        const {status, data} = await deleteItem(id);
        if (status === 200) {
          context.commit("CLEAR_ITEM", data);
        }
      } catch ({message, response = {}}) {
        context.commit(
          "SET_CREATE_ERROR",
          parseStatus(response.status) || message
        );
      }
    },
  },
  mutations: {
    SET_LIST_LAST_UPDATE(state, payload) {
      state.listLastUpdate = payload;
    },
    SET_CREATE_ERROR(state, code) {
      state.errors.create = code;
      console.error(code);
    },
    SET_ERROR(state, code) {
      state.errors.common = code;
      console.error(code);
    },
    SET_ITEM(state, item) {
      state.editItem = item;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
  },
};
export default store;
