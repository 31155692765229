import client from './client';

const getWidgets = async (from, to, points) => {
    return await client.get('/statistics/widgets', {
        params: {
            date_from: from,
            date_to: to,
            points
        }
    });
};

export {
    getWidgets
}
