<template>
  <div class="sideBar__logo">
    <router-link to="/"
      ><img src="../../assets/img/logo_icon.svg" alt=""
    /></router-link>
  </div>
  <div class="sideBar__components">
    <ul class="sideBar__list">
      <NavigationGroup
        v-for="(group, i) in links"
        v-bind:key="i"
        :group="group"
      />
    </ul>
  </div>
  <a class="sideBar__exit-btn" @click.prevent="exit" href="#exit">Выход здесь</a>
</template>

<script>
import NavigationGroup from "./NavigationGroup.vue";

export default {
  name: "Sidebar",
  components: {
    NavigationGroup,
  },
  computed: {
    links() {
      return this.$store.getters["navLinks/getUserNavLinks"];
    },
  },
  methods: {
    exit() {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>

<style lang="scss">
.sideBar__exit-btn {
  display: block;
  background: #556ee6;
  padding: 16px 30px;
  position: absolute;
  bottom: 13px;
  width: 100%;
  color: #ffffff;
  text-decoration: none;
  &:hover,
  &:visited {
    color: #ffffff;
  }
}
</style>
