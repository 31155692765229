<template>
    <form class="ModalForm form-send-mail" novalidate @submit.prevent="restore">
        <label class="DescriptionLog">Введите почту</label>
        <Input placeholder="Почта" class="marginModal" type="text" required>
            <template v-slot:label>
                <label class="ModalExplanation">На данную почту будет выслан код, для восстановления пароля</label>
            </template>
        </Input>
        <div class="invalid-feedback" v-if="error">
            Электронный адрес получателя не существует или не найден
        </div>
        <input class="SendMail" type="submit" value="Отправить" readonly>
    </form>
</template>

<script>
    import Input from './Input.vue'

    export default {
        name: 'ForgetForm',
        components: {
            Input
        },
        computed: {
            error() {
                return null
            },
            user() {
                return this.$store.state.user.form
            },
        },
        methods: {
            async restore() {
                try {
                    await this.$store.dispatch('user/restore')
                } catch (e) {
                    console.error(e)
                }
            },
        },
    }
</script>
