<template>
  <Toolbar>
    <template v-slot:filter>
      <Search
        v-model:value="filters.search"
        :placeholder="titles.search"
        @search="getList({ page: 1 })"
      />
      <Select
        :options="roleSelect"
        v-model:value="filters.role"
        :placeholder="titles.role"
        @search="getList({ page: 1 })"
      />
      <Select
        :options="statusSelect"
        v-model:value="filters.status"
        :placeholder="titles.status"
        @search="getList({ page: 1 })"
      />
      <Select
        :options="regionSelect"
        v-model:value="filters.region"
        :placeholder="titles.region"
        @search="getList({ page: 1 })"
      />
    </template>
    <template v-slot:buttons>
      <Button @click="openUserAdd" modifyer="users-item"
        >Добавить пользователя</Button
      >
    </template>
  </Toolbar>
  <Grid
    :head="tableHead"
    :data="users"
    :cellClass="cellClass"
    @clickrow="true"
    @row-click="onRowClick"
    v-if="users.length > 0"
  />
  <div v-else-if="usersLoading" class="pt-3 pb-3">Загрузка...</div>
  <div v-else class="pt-3 pb-3">Нет информации</div>
  <Pages
    v-if="users.length"
    :totalPages="totalPages"
    :currentPage="filters.page"
    :last="totalPages"
    :total="total"
    :perPage="perPage"
    @change="switchPage"
  />
  <UserEdit
    @close="onEditClose"
    @user:change="onUserChange"
    @user:delete="onUserDelete"
    @user:save="onUserSave"
    :user="editUser"
    :loading="editUserLoading"
  />
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import Toolbar, { Search, Select } from "src/components/toolbar";
import Button from "src/components/base/Button.vue";
import Grid from "src/components/grid";
import UserEdit from "src/components/userforms/UserEdit.vue";
import Pages from "src/components/Pages.vue";
// import { USERS_PAGE_LIMIT } from "src/constants";

const EDIT_FILEDS = [
  "first_name",
  "middle_name",
  "last_name",
  "email_adress",
  "phone_primary",
  "role_id",
  "point_id",
  "birthday",
  "passport",
  "password",
];

export default {
  name: "Users",
  components: {
    Toolbar,
    Search,
    Select,
    Button,
    Grid,
    UserEdit,
    Pages,
  },
  setup() {
    const store = useStore();
    const usersLoading = ref(true);

    store.commit("SET_TITLE", "Пользователи");

    let dicts = [
      store.dispatch("dicts/getRegions"),
      store.dispatch("dicts/getTimezones"),
    ];

    if (!store.state.dicts.userRoles.length) {
      dicts.push(store.dispatch("dicts/getUserRoles"));
    }
    if (!store.state.points.list.length) {
      dicts.push(store.dispatch("points/getList"));
    }

    Promise.all(dicts).then(async () => {
      await store.dispatch("user/getUsers");
      usersLoading.value = false;
    });

    return { usersLoading };
  },
  computed: {
    totalPages() {
      return this.$store.state.user.pagination.totalPages;
    },
    total() {
      return this.$store.state.user.pagination.totalItems;
    },
    perPage() {
      return this.$store.state.user.pagination.perPage;
    },
    roles() {
      return this.$store.state.dicts.userRoles;
    },
    regions() {
      return this.$store.state.dicts.regions || {};
    },
    timezones() {
      return this.$store.state.dicts.timezones || {};
    },
    users() {
      const userList = this.$store.state.user.list || [];
      const roles = this.roles;
      const regions = this.regions;
      const timezones = this.timezones;
      return userList.map((user) => {
        const {
          id,
          first_name,
          middle_name,
          last_name,
          email_adress,
          phone_primary,
          region_id,
          role_id,
          timezone_id,
          status,
          registration_date,
        } = user;

        return {
          id,
          fio: `${first_name} ${middle_name} ${last_name}`,
          role: roles.filter((item) => item.id === role_id)[0].authority,
          email_adress,
          phone_primary,
          region: regions[region_id]?.title || "",
          timezone: timezones[timezone_id]?.title || "",
          status,
          registration_date,
        };
      });
    },
    roleSelect() {
      return this.roles.map((item) => {
        return { value: item.id, title: item.authority };
      });
    },
    regionSelect() {
      return this.$store.state.dicts.rawDict.regions;
    },
  },
  data() {
    return {
      editUser: undefined,
      addUser: undefined,
      filters: {
        page: 1,
        search: "",
        status: -1,
        role: -1,
        region: -1,
      },
      showAdd: false,
      titles: {
        role: "Все роли",
        search: "Поиск",
        status: "Все статусы",
        region: "Все регионы",
      },
      statusSelect: [
        { value: 0, title: "Активный" },
        { value: 1, title: "Заблокирован" },
      ],
      tableHead: {
        id: "ID",
        fio: "ФИО",
        role: "Роль",
        email_adress: "Email",
        phone_primary: "Телефон",
        region: "Регион",
        timezone: "Часовой пояс",
        status: "Статус",
        registration_date: "Дата регистрации",
      },
      editUserLoading: false,
    };
  },
  watch: {
    "$store.state.user.newUserTimestamp"() {
      this.getList();
    },
  },
  methods: {
    async switchPage(page) {
      this.filters.page = page;
      await this.getList();
    },
    async getList(opts) {
      this.filters = { ...this.filters, ...opts };
      this.usersLoading = true;
      await this.$store.dispatch("user/getUsers", this.filters);
      this.usersLoading = false;
    },
    openUserAdd() {
      this.$store.commit("user/CREATE_USER", {});
    },
    cellClass(name) {
      return `users-item users-item_${name}`;
    },
    onRowClick(row) {
      const selectedUser = this.$store.state.user.list.find(item => item.id === row.id);
      this.editUser = {};
      Object.assign(this.editUser, selectedUser);
    },
    onEditClose() {
      this.editUser = undefined;
      this.$store.commit("user/SET_ERRORS", {
        key: "saveUser",
        val: {},
      });
    },
    onUserChange(user) {
      Object.assign(this.editUser, user);
    },
    async onUserDelete() {
      this.editUserLoading = true;
      await this.$store.dispatch("user/delete", this.editUser.id);
      this.editUserLoading = false;
      this.editUser = undefined;
      await this.getList({ page: 1 });
    },
    async onUserSave() {
      this.editUserLoading = true;
      const patch = EDIT_FILEDS.reduce((acc, field) => {
        acc[field] = this.editUser[field];
        return acc;
      }, {});
      patch.id = this.editUser.id;
      await this.$store.dispatch("user/save", patch);
      this.editUserLoading = false;
      await this.getList();
    },
  },
};
</script>

<style lang="scss">
.users-item {
  width: 120px;
  margin-right: 5px;
  &-button {
    width: 100%;
  }
  &_id {
    width: 40px;
  }
  &_fio {
    //width: 40px;
  }
  &_role {
    //width: 40px;
  }
  &_email_adress {
    width: 200px;
    overflow: hidden;
  }
  &_phone_primary {
    //width: 40px;
  }
  &_region {
    //width: 40px;
  }
  &_timezone {
    width: 80px;
  }
  &_status {
    width: 80px;
  }
  &_registration_date {
    width: 100px;
    margin-right: 0;
  }
}
</style>
