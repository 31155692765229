<template>
    <div class="hover-notification">
        <h2 class="hover-notification-title">Уведомления</h2>
        <div class="hover-notification-items-container">
            <NotifyDropdownItem v-for="item in list" v-bind:key="item.id" :item="item"/>
            <router-link class="mainButton notificationButton" to="/notifications">Все уведомления</router-link>
        </div>
    </div>
</template>

<script>
    import NotifyDropdownItem from './NotifyDropdownItem.vue'
    import { NotificationsType } from 'src/types'

    export default {
        name: 'NotifyDropdown',
        props: {
            notifications: NotificationsType
        },
        components: {
            NotifyDropdownItem
        },
        computed: {
            list() {
                return this.notifications.slice(0, 5)
            }
        },
        setup() {
            
        },
    }
</script>