import positiveSound from '@/assets/audio/neutral.mp3';
import negativeSound from '@/assets/audio/negative.mp3';
import {onMounted} from "@vue/runtime-core";

const useSound = () => {
  let positiveAudio = null;
  let negativeAudio = null;

  const playPositiveSound = () => {
    if (positiveAudio) {
      positiveAudio.play();
    }
  }
  const playNegativeSound = () => {
    if (negativeAudio) {
      negativeAudio.play();
    }
  }

  onMounted(() => {
    positiveAudio = new Audio(positiveSound); // path to file
    negativeAudio = new Audio(negativeSound);
  });

  return {
    playPositiveSound,
    playNegativeSound
  };
}

export {useSound}
