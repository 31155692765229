import {onBeforeMount} from 'vue';

const BLOCK_LENGTH = 20;
const RESET_DELAY = 1000 * 10;

const useBarcodeScan = () => {
  let barcode = '';
  let timeout;

  const runResetTimeout = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      barcode = '';
    }, RESET_DELAY);
  }

  const handleKeys = (e) => {

    // Если нажали ENTER и имеем правильную длину блока парсим
    if (e.keyCode === 13) {
      if (barcode.length > 5 && barcode.length < BLOCK_LENGTH) {
        e.preventDefault();
        // Всегда отправляем событие с отсканированным баркодом
        const event = new CustomEvent('barcodescan', {detail: barcode});
        window.dispatchEvent(event);

      }
      barcode = '';
    }

    // Набираем блок из цифр
    if (/^[a-zA-Z0-9\-*]{1}$/.test(e.key)) {
      // Если нет символов стартуем таймер
      if (!barcode) runResetTimeout();

      barcode = barcode + e.key;
      console.debug(`code: ${barcode} (${barcode.length})`);

      if (barcode.length > BLOCK_LENGTH) {
        barcode = '';
      }
    }
  }

  onBeforeMount(() => window.addEventListener('keydown', handleKeys, true));

  // функция которая пытается достать id из EAN
  const parseEan = (eanBarcode) => {
    console.log(eanBarcode)
    if (eanBarcode.length === 13) {
      const payload = '' + parseInt(eanBarcode.substring(1));
      if (payload) {
        return +payload.substring(0, payload.length - 1);
      }
    }
    return eanBarcode
  }

  return {
    parseEan
  };
}

export {useBarcodeScan}
