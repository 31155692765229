<template>
    <router-link class="notification" to="/notifications">
        <img src="../../assets/img/notification_button.svg" alt="notification">
        <span class="notification-quantity">{{ showCount }}</span>
    </router-link>
</template>

<script>
    export default {
        name: 'NotifyBtn',
        props: {
            count: Number
        },
        computed: {
            showCount() {
                return this.count > 99 ? '99+' : this.count
            }
        },
    }
</script>