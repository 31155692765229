<template>
  <div class="right-slide" v-if="user">
    <div class="right-slide_back" @click="onClose"></div>
    <div class="right-slide_panel">
      <a class="right-slide_close" href="#close" @click="onClose"
        ><i class="close-icon"></i
      ></a>
      <h2>Профиль пользователя</h2>
      <form>
        <div class="right-slide_form-container">
          <div class="right-slide_form-item">
            <label class="right-slide_item_label">ФИО</label>
            <input
              class="form-control emptyCheck style-profile-all-input"
              :class="{
                'is-invalid':
                  (v$.user.last_name.$dirty && v$.user.last_name.$invalid) ||
                  backendErrors.last_name,
              }"
              type="text"
              placeholder="Фамилия"
              :value="user.last_name"
              @input="onInput('last_name', $event)"
            />
            <div
              v-if="
                (v$.user.last_name.$dirty && v$.user.last_name.$invalid) ||
                backendErrors.last_name
              "
              class="invalid-feedback"
            >
              <p
                v-if="v$.user.last_name.$dirty && v$.user.last_name.$invalid"
                class="invalid-feedback-text"
                >Неверный формат</p
              >
              <p v-if="backendErrors.last_name" class="invalid-feedback-text">{{
                backendErrors.last_name[0]
              }}</p>
            </div>
          </div>

          <div class="right-slide_form-item">
            <input
              class="form-control emptyCheck style-profile-all-input"
              :class="{
                'is-invalid':
                  (v$.user.first_name.$dirty && v$.user.first_name.$invalid) ||
                  backendErrors.first_name,
              }"
              type="text"
              placeholder="Имя"
              :value="user.first_name"
              @input="onInput('first_name', $event)"
            />
            <div
              v-if="
                (v$.user.first_name.$dirty && v$.user.first_name.$invalid) ||
                backendErrors.first_name
              "
              class="invalid-feedback"
            >
              <p
                v-if="v$.user.first_name.$dirty && v$.user.first_name.$invalid"
                class="invalid-feedback-text"
                >Неверный формат</p
              >
              <p
                v-if="backendErrors.first_name"
                class="invalid-feedback-text"
                >{{ backendErrors.first_name[0] }}</p
              >
            </div>
          </div>

          <div class="right-slide_form-item">
            <input
              class="form-control emptyCheck style-profile-all-input"
              :class="{
                'is-invalid':
                  (v$.user.middle_name.$dirty &&
                    v$.user.middle_name.$invalid) ||
                  backendErrors.middle_name,
              }"
              type="text"
              placeholder="Отчество"
              :value="user.middle_name"
              @input="onInput('middle_name', $event)"
            />
            <div
              v-if="
                (v$.user.middle_name.$dirty && v$.user.middle_name.$invalid) ||
                backendErrors.middle_name
              "
              class="invalid-feedback"
            >
              <p
                v-if="
                  v$.user.middle_name.$dirty && v$.user.middle_name.$invalid
                "
                class="invalid-feedback-text"
                >Неверный формат</p
              >
              <p
                v-if="backendErrors.middle_name"
                class="invalid-feedback-text"
                >{{ backendErrors.middle_name[0] }}</p
              >
            </div>
          </div>

          <div class="right-slide_form-item">
            <label class="right-slide_item_label">Роль</label>
            <Select
              :class="{
                'is-invalid':
                  (v$.user.role_id.$dirty && v$.user.role_id.$invalid) ||
                  backendErrors.role_id,
              }"
              :options="roleSelect"
              :value="user.role_id"
              placeholder="Выберите роль"
              @select="onSelect('role_id', $event)"
            />
            <div
              v-if="
                (v$.user.role_id.$dirty && v$.user.role_id.$invalid) ||
                backendErrors.role_id
              "
              class="invalid-feedback"
            >
              <p
                v-if="v$.user.role_id.$dirty && v$.user.role_id.$invalid"
                class="invalid-feedback-text"
                >Неверный формат</p
              >
              <p v-if="backendErrors.role_id" class="invalid-feedback-text">{{
                backendErrors.role_id[0]
              }}</p>
            </div>
          </div>

          <div class="right-slide_form-item">
            <label class="right-slide_item_label">Пункт выдачи</label>
            <Select
              :class="{
                'is-invalid':
                  (v$.user.point_id.$dirty && v$.user.point_id.$invalid) ||
                  backendErrors.point_id,
              }"
              :options="pointSelect"
              :value="user.point_id >= 0 ? user.point_id : -1"
              placeholder="Не назначать"
              @select="onSelect('point_id', $event)"
            />
            <div
              v-if="
                (v$.user.point_id.$dirty && v$.user.point_id.$invalid) ||
                backendErrors.point_id
              "
              class="invalid-feedback"
            >
              <p
                v-if="v$.user.point_id.$dirty && v$.user.point_id.$invalid"
                class="invalid-feedback-text"
                >Неверный формат</p
              >
              <p v-if="backendErrors.point_id" class="invalid-feedback-text">{{
                backendErrors.point_id[0]
              }}</p>
            </div>
          </div>

          <div class="right-slide_form-item right-slide_hgroup_2">
            <div class="right-slide_form-item right-slide_hgroup_2-item">
              <label class="right-slide_item_label">Email</label>
              <input
                class="form-control emptyCheck style-profile-all-input"
                :class="{
                  'is-invalid':
                    (v$.user.email_adress.$dirty &&
                      v$.user.email_adress.$invalid) ||
                    backendErrors.email_adress,
                }"
                type="text"
                placeholder="Введите Email"
                :value="user.email_adress"
                @input="onInput('email_adress', $event)"
              />
              <div
                v-if="
                  (v$.user.email_adress.$dirty &&
                    v$.user.email_adress.$invalid) ||
                  backendErrors.email_adress
                "
                class="invalid-feedback"
              >
                <p
                  v-if="
                    v$.user.email_adress.$dirty && v$.user.email_adress.$invalid
                  "
                  class="invalid-feedback-text"
                  >Неверный формат</p
                >
                <p
                  v-if="backendErrors.email_adress"
                  class="invalid-feedback-text"
                  >{{ backendErrors.email_adress[0] }}</p
                >
              </div>
            </div>

            <div class="right-slide_form-item right-slide_hgroup_2-item">
              <label class="right-slide_item_label">Телефон</label>
              <input
                class="form-control emptyCheck style-profile-all-input"
                :class="{
                  'is-invalid':
                    (v$.user.phone_primary.$dirty &&
                      v$.user.phone_primary.$invalid) ||
                    backendErrors.phone_primary,
                }"
                type="text"
                placeholder="Введите номер"
                :value="user.phone_primary"
                @input="onInput('phone_primary', $event)"
              />
              <div
                v-if="
                  (v$.user.phone_primary.$dirty &&
                    v$.user.phone_primary.$invalid) ||
                  backendErrors.phone_primary
                "
                class="invalid-feedback"
              >
                <p
                  v-if="
                    v$.user.phone_primary.$dirty &&
                    v$.user.phone_primary.$invalid
                  "
                  class="invalid-feedback-text"
                  >Неверный формат</p
                >
                <p
                  v-if="backendErrors.phone_primary"
                  class="invalid-feedback-text"
                  >{{ backendErrors.phone_primary[0] }}</p
                >
              </div>
            </div>
          </div>

          <!-- <div class="right-slide_form-item right-slide_hgroup_2">
            <div class="right-slide_form-item right-slide_hgroup_2-item">
              <Button
                modifyer="users-item"
                :class="{ inactive: tab === 'pvz' }"
                @click="switchActive('info')"
                >Информация</Button
              >
            </div>
            <div class="right-slide_form-item right-slide_hgroup_2-item">
              <Button
                modifyer="users-item"
                :class="{ inactive: tab === 'info' }"
                @click="switchActive('pvz')"
                >ПВЗ</Button
              >
            </div>
          </div> -->
          <!-- <div
            class="right-slide_form-item right-slide_hgroup_3"
            v-show="tab === 'info'"
          >
            <div class="right-slide_form-item right-slide_hgroup_3-item">
              <label class="right-slide_item_label">Пароль</label>
              <input
                class="form-control emptyCheck style-profile-all-input"
                :type="passwordType"
                placeholder="Пароль"
                :value="user.password"
                @input="hidePassword"
              />
              <div class="invalid-feedback">
                <p class="invalid-feedback-text"></p>
              </div>
            </div>
            <div class="right-slide_form-item right-slide_hgroup_3-item">
              <Button modifyer="users-item" @click="generatePass"
                >Сгенирировать</Button
              >
            </div>
            <div class="right-slide_form-item right-slide_hgroup_3-item">
              <Button modifyer="users-item" @click="onSaveUser"
                >Установить</Button
              >
            </div>
          </div> -->
          <!-- <div
            class="right-slide_form-item right-slide_hgroup_3"
            v-show="tab === 'info'"
          >
            <div class="right-slide_form-item right-slide_hgroup_3-item">
              <Button modifyer="users-item" @click="onDeleteUser"
                >Удалить аккаунт</Button
              >
            </div>
          </div>
          <div
            class="right-slide_form-item right-slide_hgroup_3"
            v-show="tab === 'pvz'"
          >
            <label class="right-slide_item_label"
              >Прикрепить к новому ПВЗ</label
            >
            <input
              class="form-control emptyCheck style-profile-all-input"
              type="text"
              placeholder="ПВЗ"
            />
            <div class="invalid-feedback">
              <p class="invalid-feedback-text"></p>
            </div>
          </div> -->

          <button
            class="edit-worker-modal-button-save"
            type="button"
            @click="toggleModal(true)"
          >
            Удалить пользователя
          </button>
          <br />
          <br />
          <button
            class="edit-worker-modal-button-save"
            type="button"
            @click="onSaveUser"
          >
            Сохранить изменения
          </button>

          <div v-if="backendErrors.error" class="is-invalid pt-3">
            <div class="invalid-feedback">
              <p class="invalid-feedback-text">{{ backendErrors.error }}</p>
            </div>
          </div>
        </div>
      </form>
    </div>

    <Modal :show="isDeleteModalShow" modifyer="usercreate">
      <div class="modal-dialog custom-modal-dialog modal-dialog-centered">
        <div class="modal-content customModalDeleteWorker w-100">
          <div class="modal-header custom-modal-header">
            <h2 class="custom-modal-header-heading"
              >Вы уверены, что хотите удалить пользователя?</h2
            >
          </div>
          <div class="modal-body">
            <form
              class="modal-delete-worker deleteWorkerValidation"
              novalidate=""
            >
              <div class="row">
                <div class="col">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#editWorkerModal"
                    class="delete-worker-modal-button-save cancel"
                    @click="toggleModal(false)"
                    :disabled="loading"
                  >
                    Отменить
                  </button>
                </div>
                <div class="col">
                  <button
                    type="submit"
                    class="delete-worker-modal-button-save"
                    @click="onDeleteUser"
                    :disabled="loading"
                  >
                    Подтвердить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Select from "src/components/userforms/Select.vue";
import Modal from "src/components/modal/Modal.vue";
import { generatePassword } from "src/helpers/generators";

import useVuelidate from "@vuelidate/core";
import { required, email, numeric, minValue } from "@vuelidate/validators";

export default {
  name: 'UserEdit',
  components: {
    Select,
    Modal,
  },
  props: {
    user: Object,
    loading: Boolean,
  },
  data() {
    return {
      passwordType: "password",
      tab: "info",
      isDeleteModalShow: false,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    roleSelect() {
      return this.$store.state.dicts.userRoles.map((item) => {
        return { value: item.id, title: item.authority };
      });
    },
    pointSelect() {
      return this.$store.state.points.list.map((item) => {
        return { value: item.id, title: item.name };
      });
    },
    backendErrors() {
      return this.$store.state.user.errors.saveUser;
    },
  },
  validations() {
    return {
      user: {
        last_name: { required },
        first_name: { required },
        middle_name: { required },
        role_id: { required, minValue: minValue(0) },
        point_id: {},
        email_adress: { required, email },
        phone_primary: { required, numeric },
      },
    };
  },
  methods: {
    switchActive(tab) {
      this.tab = tab;
    },
    generatePass() {
      this.$emit("user:change", { password: generatePassword() });
      this.passwordType = "text";
    },
    hidePassword({ target }) {
      this.$emit("user:change", { password: target.value });
      this.passwordType = "password";
    },
    onClose() {
      this.$emit("close");
    },
    async onSaveUser() {
      if (this.loading) return;

      this.v$.$touch();
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.v$.$reset();
        this.$emit("user:save");
      }
    },
    onDeleteUser() {
      if (this.loading) return;

      this.$emit("user:delete");
      this.toggleModal(false);
    },
    onInput(field, { target }) {
      this.v$.user[field].$touch();
      this.$emit("user:change", { [field]: target.value });
    },
    onSelect(field, val) {
      this.v$.user[field].$touch();
      this.$emit("user:change", { [field]: val });
    },
    toggleModal(val) {
      this.isDeleteModalShow = val;
    },
  },
};
</script>

<style lang="scss">
.right-slide {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
  &_back {
    position: fixed;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    background: #000000;
  }
  &_panel {
    position: fixed;
    height: 100%;
    min-width: 300px;
    right: 0px;
    top: 0px;
    background: #2a3042;
    color: #ffffff;
    padding: 30px;
  }
  &_close {
    position: fixed;
    right: 25px;
    height: 25px;
    .close-icon {
      background-color: #ffffff;
    }
  }
  &_form-item {
    width: 600px;
    padding: 0 0 10px 0;
    position: relative;
    vertical-align: top;
  }
  h2 {
    margin-bottom: 15px;
  }
  &_invalid-feedback {
    display: none;
  }
  &_item_label {
    margin: 10px;
  }
  &_hgroup_2 {
    &-item {
      display: inline-block;
      width: 48%;
      &:first-child {
        margin-right: 4%;
      }
    }
  }
  &_hgroup_3 {
    &-item {
      display: inline-block;
      width: 32%;
      margin-right: 2%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .inactive {
    background: transparent;
  }
}
</style>
