import Shipments from "./Shipments.vue";
import ShipmentsDetail from "./ShipmentsDetail.vue";
import ShipmentsSetStatus from "@/views/shipments/admin/ShipmentsSetStatus";

export const shipmentsRoutes = [
  {
    path: "/shipments",
    component: Shipments,
    name: "shipments",
  },
  {
    path: "/shipments/:id",
    component: ShipmentsDetail,
    name: "shipmentsDetail",
    meta: { isHeaderBackLink: true },
  },
  {
    path: "/shipments/set-status-for-shipments",
    component: ShipmentsSetStatus,
    name: "ShipmentsSetStatus",
    meta: { isHeaderBackLink: true },
  },
];
