import {
  // getAll,
  //   createItem,
  readItem,
  updateItem,
  deleteItem,
  surplus,
  destroyed,
  issued,
  accept,
  placed,
  placedShipmentFromFreight,
  code,
  cancelled,
  getPackageTypes,
  getHistory, getShipmentCode, getForFreight,
} from "src/api/shipments";

import { USERS_PAGE_LIMIT, SHIPMENT_STATUS_VALUES } from "@/constants";
import {isDataUpdateRequired} from "@/store/helper";

const store = {
  namespaced: true,
  state() {
    return {
      packageTypes: [],
      packageTypesLastUpdate: null,
      history: [],
      list: [],
      surplus: [],
      destroyed: [],
      pagination: {
        totalPages: 1,
        totalItems: 0,
        perPage: 0
      },
      readItem: {},
      statuses: SHIPMENT_STATUS_VALUES,
      errors: {
        getHistory: {},
        getPackageTypes: {},
        getList: {},
        deleteItem: {},
        readItem: {},
        updateItem: {},
        issued: {},
        placed: {},
        placedShipmentFromFreight: {},
      },
    };
  },
  getters: {
    statusOptions(state) {
      return Object.keys(state.statuses).map((key) => {
        return { value: parseInt(key), title: state.statuses[key] };
      });
    },
    statusNameById: (state) => (id) => {
      return state.statuses[parseInt(id)] || "";
    },
    statuses: (state) => {
      return state.statuses;
    },
  },
  actions: {
    async getHistory(context, id) {
      context.commit("SET_ERRORS", {
        key: "getHistory",
        val: {},
      });

      try {
        const resp = await getHistory(id);
        context.commit("SET_HISTORY", resp.data);

      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getHistory",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getHistory",
            val: { error: "Ошибка" },
          });
        }
      }
    },
    async getPackageTypes(context) {
      if (context.state.packageTypesLastUpdate && context.state.packageTypes.length > 0 && !isDataUpdateRequired(context.state.packageTypesLastUpdate)) {
        return;
      }

      try {
        const resp = await getPackageTypes();
        context.commit("SET_PACKAGE_TYPES", resp.data);

      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getPackageTypes",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getPackageTypes",
            val: { error: "Ошибка" },
          });
        }
      }
      context.commit("SET_PACKAGE_TYPES_LAST_UPDATE", new Date().getTime());
    },
    async getList(context, filters = {}) {
      context.commit("SET_ERRORS", {
        key: "getList",
        val: {},
      });

      const filter = {};
      if (filters.shipmentId) {
        filter.id = filters.shipmentId;
      }
      if (filters.items) {
        filter.items = USERS_PAGE_LIMIT;
      }
      if (filters.page > -1) {
        filter.page = filters.page;
      }
      if (filters.search) {
        filter.search = filters.search;
      }
      if (filters.date_from) {
        filter.date_from = filters.date_from;
      }
      if (filters.date_to) {
        filter.date_to = filters.date_to;
      }
      if (filters.status || filters.status === 0) {
        filter.status = filters.status;
      }
      if (filters.back_status || filters.back_status === 0) {
        filter.back_status = filters.back_status;
      }
      if (filters.from || filters.from === 0) {
        filter.src_point_id = filters.from;
      }
      if (filters.to || filters.to === 0) {
        filter.dts_point_id = filters.to;
      }
      if (filters.searchByAll) {
        filter.searchByAll = filters.searchByAll;
      }


      // Фильтр для получения остатков
      if (filters.surplus) {
        filter.surplus = true
      }

      if (filters.franchisee) {
        filter.franchisee = true
      }

      // eslint-disable-next-line no-prototype-builtins
      if (filters.hasOwnProperty('freight_id')) {
        filter.freight_id = filters.freight_id;
      }

      try {
        const resp = await getForFreight(filter);
        context.commit("SET_LIST", resp.data);

        context.commit("SET_PAGINATION", {
          key: "totalPages",
          val: resp.headers["last_page"] * 1 || 1,
          totalItems: resp.headers["total"],
          perPage: resp.headers["per_page"]
        });
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "getList",
            val: { error: "Ошибка" },
          });
        }
      }
    },

    async getSurplus({commit}) {
      commit("SET_ERRORS", {
        key: "getSurplus",
        val: {},
      });

      try {
       const resp = await surplus(`/shipments/surplus`);
       commit("SET_SURPLUS", resp.data.data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          commit("SET_ERRORS", {
            key: "getSurplus",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "getSurplus",
            val: { error: "Ошибка" },
          });
        }
      }
    },

    async getDestroyed({commit}) {
      commit("SET_ERRORS", {
        key: "getDestroyed",
        val: {},
      });

      try {
        const resp = await destroyed();
        commit("SET_DESTROYED", resp.data.data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          commit("SET_ERRORS", {
            key: "getDestroyed",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "getDestroyed",
            val: { error: "Ошибка" },
          });
        }
      }
    },

    async delete(context, id) {
      context.commit("SET_ERRORS", {
        key: "deleteItem",
        val: {},
      });
      try {
        await deleteItem(id);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "deleteItem",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "deleteItem",
            val: { error: "Ошибка" },
          });
        }
      }
    },
    async readItem(context, id) {
      context.commit("SET_ERRORS", {
        key: "readItem",
        val: {},
      });

      try {
        const resp = await readItem(id);
        context.commit("SET_READ_ITEM", resp.data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "readItem",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "readItem",
            val: { error: "Ошибка" },
          });
        }
        //throw new Error(e.response?.data?.error || e.message)
      }
    },
    async getShipmentCode(context, id) {
      try {
        return await getShipmentCode(id);
      } catch (e) {
        console.log(e)
      }
    },
    async updateItem(context) {
      context.commit("SET_ERRORS", {
        key: "updateItem",
        val: {},
      });

      const payload = { ...context.state.readItem };
      const EDIT_FILEDS = ["status", "src_point_id", "cur_point_id", "dts_point_id", "ceil_id", "comment", 'control_delivery_date'];

      const data = EDIT_FILEDS.reduce((acc, field) => {
        acc[field] = payload[field];
        return acc;
      }, {});

      try {
        await updateItem(payload.uuid, data);
      } catch (e) {
        if (e.response && (e.response.status === 422 || e.response.status === 402) ) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "updateItem",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "updateItem",
            val: { error: "Ошибка" },
          });
        }
      }
    },
    async placedShipmentFromFreight(context, { id, data }) {
      context.commit("SET_ERRORS", {
        key: "placedShipmentFromFreight",
        val: {},
      });

      try {
        return await placedShipmentFromFreight(id, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "placedShipmentFromFreight",
            val: e.response.data.error,
          });
        } else if (e.response && e.response.status === 402) {
          context.commit("SET_ERRORS", {
            key: "placedShipmentFromFreight",
            val: e.response.data.message,
          });
          throw new Error(e.response.data.message);
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "placedShipmentFromFreight",
            val: { error: "Ошибка" },
          });
        }
        throw new Error(e.message)
      }
    },
    async placed(context, { id, data }) {
      context.commit("SET_ERRORS", {
        key: "placed",
        val: {},
      });

      try {
        await placed(id, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "placed",
            val: e.response.data.error,
          });
        } else if (e.response && e.response.status === 402) {
          context.commit("SET_ERRORS", {
            key: "placed",
            val: { error: e.response.data.message },
          });
          throw new Error(e.response.data.message);
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "placed",
            val: { error: "Ошибка" },
          });
        }
        throw new Error(e.message)
      }
    },
    async issued(context, { uuid, data = {} }) {
      context.commit("SET_ERRORS", {
        key: "issued",
        val: {},
      });

      try {
        await issued(uuid, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "issued",
            val: e.response.data.error,
          });
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "issued",
            val: { error: "Ошибка" },
          });
        }
        throw new Error(e.response.data.error || e.message)
      }
    },
    async accept(context, { id, data = {} }) {
      context.commit("SET_ERRORS", {
        key: "accept",
        val: {},
      });

      try {
        return await accept(id, data);
      } catch (e) {
        if (e.response && e.response.status === 422) {
          console.log(e.response);
          context.commit("SET_ERRORS", {
            key: "accept",
            val: e.response.data.error,
          });
        } else if (e.response && e.response.status == 402) {
          context.commit("SET_ERRORS", {
            key: "accept",
            val: e.response.data.message,
          });
          return Promise.reject(e.response.data.message);

        } else if (e.response && e.response.status == 403) {
          return Promise.reject(e.response.data.message);
        } else {
          console.log("Ошибка " + e.message);
          context.commit("SET_ERRORS", {
            key: "accept",
            val: { error: "Ошибка" },
          });
        }
        throw new Error(e.message)
      }
    },
    async code(context, {uuid, shipmentCodeType}) {
      return await code(uuid, shipmentCodeType);
    },
    async cancelled(context, { id, reasonId }) {
      try {
        await cancelled(id, reasonId);
        context.dispatch('readItem', id);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mutations: {
    SET_PACKAGE_TYPES_LAST_UPDATE(state, payload) {
      state.packageTypesLastUpdate = payload;
    },
    SET_PACKAGE_TYPES(state, payload) {
      state.packageTypes = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination[payload.key] = payload.val;
      state.pagination.totalItems = payload.totalItems;
      state.pagination.perPage = payload.perPage;
    },
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_SURPLUS(state, payload) {
      state.surplus = payload;
    },
    SET_DESTROYED(state, payload) {
      state.destroyed = payload;
    },
    SET_HISTORY(state, payload) {
      state.history = payload;
    },
    SET_READ_ITEM(state, payload) {
      state.readItem = payload;
    },
  },
};
export default store;
