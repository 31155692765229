import { createStore } from "vuex";
import user from "./user";
import dicts from "./dicts";
import accounts from "./accounts";
import clients from "./clients";
import freights from "./freights";
import operations from "./operations";
import partners from "./partners";
import points from "./points";
import schedules from "./schedules";
import shipments from "./shipments";
import warehouses from "./warehouses";
import navLinks from "./navLinks";
import ceils from "./ceils";
import sendings from "./sendings";
import refuse from "./refuse";
import statistic from "./statistic";
import reports from "./reports";

import apiV2ShipmentStore from "@/store/V2/apiV2ShipmentStore";
import apiV2ShipmentLogStore from "@/store/V2/apiV2ShipmentLogStore";
import apiV2ShipmentCodeTypeStore from "@/store/V2/apiV2ShipmentCodeTypeStore";
import apiV2SendingStore from "@/store/V2/apiV2SendingStore";
import apiV2DocumentStore from "@/store/V2/apiV2DocumentStore";
import apiV2HelpdeskStore from  "@/store/V2/apiV2HelpdeskStore";
import apiV2PartnersStore from "@/store/V2/apiV2PartnersStore";
import apiV2PpActStore from "@/store/V2/apiV2PpActStore";
import apiV2ShopStore from "@/store/V2/apiV2ShopStore";
import apiV2NewsStore from "@/store/V2/apiV2NewsStore";
import apiV2RegistrationStore from "@/store/V2/apiV2RegistrationStore";

const store = createStore({
  state() {
    return {
      title: "",
      isLoading: false
    };
  },
  modules: {
    user,
    navLinks,
    dicts,
    accounts,
    clients,
    freights,
    operations,
    partners,
    points,
    schedules,
    shipments,
    warehouses,
    ceils,
    sendings,
    refuse,
    statistic,
    reports,

    apiV2ShipmentStore,
    apiV2SendingStore,
    apiV2DocumentStore,
    apiV2HelpdeskStore,
    apiV2ShipmentLogStore,
    apiV2PartnersStore,
    apiV2PpActStore,
    apiV2ShopStore,
    apiV2NewsStore,
    apiV2RegistrationStore,
    apiV2ShipmentCodeTypeStore,
  },
  actions: {
    loading({ state }, isLoading) {
      state.isLoading = isLoading;
    }
  },
  mutations: {
    SET_TITLE(state, title) {
      state.title = title;
    },
  },
});

export default store;
